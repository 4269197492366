/*.front  .section-content {margin-top: 15%;}*/
.flexslider .slides img {
  height: auto;
}
// .slider-accueil {
//   margin-top: -13px;
// }
/*Flexslider Home*/
.front {
  .flexslider {
    margin: 0;
    background: none;
    border: none;
    position: relative;
    border-radius: 0;
    box-shadow: none;
    zoom: 1;
    ul.slides li {
      position: relative !important;
      span.not_displayed {
        display: none;
      }
      .content-slider-item {
        position: absolute;
        left: 20%;
        width: 30%;
        top: 13%;
        padding: 16px;
        color: #ffffff;
        z-index: 100;
        h2 {
          margin: 0;
          a {
            color: #ffffff;
            text-decoration: none;
            font-size: 200%;
            line-height: .8em;
            font-weight: bold;
          }
        }
        h3 {
          color: #ffffff;
          font-size: 195%;
          font-weight: bold;
        }
        p.link-next a {
          background-color: #96132b;
          color: #ffffff;
          padding: 5px 20px;
          border-radius: 15px;
          text-align: right;
          display: inline-block;
          text-decoration: none;
        }
      }
    }
  }
  .flex-control-nav {
    bottom: 5px;
    z-index: 110;
    li {
      margin: 0 8px;
      a {
        height: 22px;
        width: 22px;
        background: #ffffff;
      }
    }
  }
  .flex-control-paging li a.flex-active {
    background: #96132b;
  }
  .flexslider:hover .flex-direction-nav a {
    color: #96132b;
  }
  .flex-direction-nav a {
    height: 45px;
  }
  .footer-home {
    margin-top: 0;
    border-bottom: 10px solid $brand-primary;
  }

}
/*Flexslider Home Medical*/
.home-medical {
  .region-preface-second {
    left: 0;
    margin: 0;
  }
  .flexslider {
    margin: 0;
    background: none;
    border: none;
    position: relative;
    border-radius: 0;
    box-shadow: none;
    zoom: 1;
    ul.slides li {
      position: relative !important;
      span.not_displayed {
        display: none;
      }
      .content-slider-item {
        position: absolute;
        left: 20%;
        width: 30%;
        top: 13%;
        padding: 16px;
        color: #ffffff;
        z-index: 100;
        h2 {
          margin: 0;
          a {
            color: #bdeaf2;
            text-decoration: none;
            font-size: 200%;
            line-height: .8em;
            font-weight: bold;
          }
        }
        h3 {
          color: #ffffff;
          font-size: 195%;
          font-weight: bold;
        }
        p.link-next a {
          background-color: #1badc7;
          color: #ffffff;
          padding: 5px 20px;
          border-radius: 15px;
          text-align: right;
          display: inline-block;
          text-decoration: none;
        }
      }
    }
  }
  .flex-control-nav {
    bottom: 30px;
    z-index: 110;
    li {
      margin: 0 8px;
      a {
        height: 22px;
        width: 22px;
        background: #ffffff;
      }
    }
  }
  .flex-control-paging li a.flex-active {
    background: #1badc7;
  }
  .flexslider:hover .flex-direction-nav a {
    color: #1badc7;
  }
}
/*Flexslider Home Paramedical*/
.home-paramedical {
  .region-preface-second {
    left: 0;
    margin: 0;
  }
  .flexslider {
    margin: 0;
    background: none;
    border: none;
    position: relative;
    border-radius: 0;
    box-shadow: none;
    zoom: 1;
    ul.slides li {
      position: relative !important;
      span.not_displayed {
        display: none;
      }
      .content-slider-item {
        position: absolute;
        left: 20%;
        width: 30%;
        top: 13%;
        padding: 16px;
        color: #ffffff;
        z-index: 100;
        h2 {
          margin: 0;
          a {
            color: #f5ceb1;
            text-decoration: none;
            font-size: 200%;
            line-height: .8em;
            font-weight: bold;
          }
        }
        h3 {
          color: #ffffff;
          font-size: 195%;
          font-weight: bold;
        }
        p.link-next a {
          background-color: #f16804;
          color: #ffffff;
          padding: 5px 20px;
          border-radius: 15px;
          text-align: right;
          display: inline-block;
          text-decoration: none;
        }
      }
    }
  }
  .flex-control-nav {
    bottom: 30px;
    z-index: 110;
    li {
      margin: 0 8px;
      a {
        height: 22px;
        width: 22px;
        background: #ffffff;
      }
    }
  }
  .flex-control-paging li a.flex-active {
    background: #f16804;
  }
  .flexslider:hover .flex-direction-nav a {
    color: #f16804;
  }
}
/*Actualites Homes Medical et Paramedical*/
/*Titre Home Medical*/
.home-medical,
.home-paramedical {
  .breadcrumb {
    position: absolute;
    left: 0;
    right: 0;
    height: 36px;
    margin: -25px auto 0;
    z-index: 2;
    width: 100%;
    background: transparent;
    border-radius: 0;
    &::before {
      position: absolute;
      content: " ";
      background: url("../img/home-titre-breadcrumb.png");
      height: 67px;
      margin-left: 10px;
      z-index: 49;
      width: 82px;
      left: 0;
      background-size: cover;
    }
    span {
      display: none;
    }
  }
  h1 {
    &.page-header {
      position: relative;
      top: -58px;
      left: 80px;
      color: $white;
      font-size: 24px;
      font-weight: bold;
      border: none;
      z-index: 100;
      margin: 38px auto;
      padding-bottom: 12px;
      background: none;
    }
  }
  .block-addthis {
    display: none;
  }
  &.medical,
  &.paramedical {
    h1 {
      &.page-header {
        background: none;
      }
    }
  }
}
.home-medical {
  .breadcrumb {
    background-color: #1badc7;
  }
}
.home-paramedical {
  .breadcrumb {
    background-color: #f16804;
  }
}
/*Specifique class medical et paramedical caché*/
._medical_cache_,
._medical_cache__paramedical_cache_,
._paramedical_cache_,
._paramedical_cache__medical_cache_ {
  display: none !important;
}
