.footer,
.footer-mediatheque {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  #block-block-6 {
    a,
    p {
      color: $brand-primary;
      margin-bottom: 5px;
      font-size: 90%;
      text-decoration: none;
      &.ffd-link,
      &.ffrd-link,
      &.sfe-link,
      &.eudf-link {
        float: left;
        display: inline-block;
        background-size: cover !important;
      }
      &.ffrd-link {
        background: url("../img/logo-partenaires-ffrd-hover.png") no-repeat left center;
        text-indent: -5000px;
        width: 138px;
        height: 73px;
      }
      &.ffd-link {
        background: url("../img/logo-partenaires-ffd-hover.png") no-repeat left center;
        text-indent: -5000px;
        width: 131px;
        height: 73px;
      }
      &.sfe-link {
        background: url("../img/logo-partenaires-sfe-hover.png") no-repeat left center;
        text-indent: -5000px;
        width: 125px;
        height: 73px;
      }
      &.eudf-link {
        background: url("../img/logo-partenaires-eudf-hover.png") no-repeat left center;
        text-indent: -5000px;
        width: 125px;
        height: 54px;
      }
    }
  }
}
body.not-front {
    .footer {
      display: block;
      border-top: 10px solid $brand-primary;
    }
  &.paramedical:not(.node-type-actualit-s)  ,&.medical:not(.node-type-actualit-s),&.sub-medical{
    .footer{
      border-top: unset;
      .footer-home {
        display: block;
        border-bottom: 10px solid $brand-primary;
      }
    }
  }
}
.medical,
.paramedical,
.sub-medical,
.sub-paramedical {
  .footer-home {
    margin-top: 0;
    .container {
      width: 100%;
    }
  }
  .footer-border {
    border-top-size: 3px;
    border-top-style: solid;
    text-align: center;
    padding-bottom: 1em;
    padding-top: .5em;
    position: relative;
    z-index: 6;
    margin-top: -1px;
    h2 {
      font-size: 13px;
      min-height: 29px;
      a {
        text-decoration: none;
      }
    }
    img {
      width: 55%;
      max-width: 45px;
    }
  }
  .view-display-id-block_5 {
    img {
      width: 100%;
      max-width: 90px;
    }
  }
}
.medical,
.sub-medical {
  .footer-border {
    border-top-color: #1badc7;
    h2 {
      a {
        color: #1badc7;
      }
    }
  }
}
.paramedical,
.sub-paramedical {
  .footer-border {
    border-top-color: #f16804;
    h2 {
      a {
        color: #f16804;
      }
    }
  }
}
#block-menu-menu-mentions-bas-de-page {
  margin: auto;
  text-align: center;
  ul {
    margin: 0;
  }
  li {
    display: inline-block;
    &:nth-child(1)::after {
      content: '|';
      display: inline-block;
    }
  }
  .nav > li,
  .nav > li.open {
    > a {
      background: transparent;
      padding: 10px 5px;
      display: inline

    }
  }
}
.region-mentions-legales {
  background-color: #e9e9e9;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .medical,
  .paramedical,
  .sub-medical,
  .sub-paramedical {
    .footer-home {
      .view-mise-en-avant-rubrique-colonne1-paramedical.view-display-id-block_1 {
        img {
          width: 65px;
          max-width: 65px;
        }
      }
      .view-mise-en-avant-rubrique-colonne1-paramedical.view-display-id-block_2 {
        img {
          width: 60px;
          max-width: 60px;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {

  .medical,
  .paramedical,
  .sub-medical,
  .sub-paramedical  {
    .footer-home {
      margin-top: 0 !important;
      padding-bottom: 0;
    }
    .footer-border {
      h2 {
        font-size: 15px;
        min-height: 29px;
        margin-top: 10px;
        a {
          text-decoration: none;
        }
      }
      img {
        width: 45px;
      }
    }
    .view-display-id-block_5 {
      img {
        width: 90px;
      }
    }
    .view-mise-en-avant-rubrique-colonne1-paramedical.view-display-id-block_1 {
      img {
        width: 65px;
        max-width: 65px;
      }
    }
    .view-mise-en-avant-rubrique-colonne1-paramedical.view-display-id-block_2 {
      img {
        width: 60px;
        max-width: 60px;
      }
    }
  }
}
