/*Vue listes référentiels*/

.view-liste-referentiels {
  h3 {
    margin-bottom: 0;
    margin-top: 15px;
    color: #e4a704;
    font-size: 130%;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    letter-spacing: normal;
  }
  .views-row {
    margin-bottom: 10px;
    clear: both;
    .views-field-title {
      font-weight: bold;
    }
    .views-field-field-lien-externe a {
      text-decoration: none;
      font-weight: bold;
    }
    .views-field-field-documents {
      img {
        margin-right: 0;
      }
      a {
        text-decoration: none;
        font-weight: bold;
        line-height: 16px;
        vertical-align: middle;
      }
    }
  }
  .pager {
    width: 10%;
    margin: auto;
    background: none;
    border: none;
    li.pager-show-more-next a {
      color: #ffffff;
      background: #96132b;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      padding: 8px 15px;
      font-size: 1.2em;
    }
  }
  .views-row-last {
    margin-bottom: 30px;
    min-height: 170px;
  }
  .view-content ul li {
    margin: 0;
    background: none !important;
    list-style: none;
    a {
      font-size: 90%;
      padding: 2px 15px;
      background-color: #000000;
      color: #ffffff;
      text-decoration: none;
      font-weight: bold;
      border-radius: 20px;
      display: inline-block;
      margin-right: 5px;
    }
    .field-item {
      display: inline-block;
    }
    .Medical a {
      background-color: #1badc7;
      margin-top: 10px;
    }
    .Paramedical a {
      background-color: #f16804;
      margin-top: 10px;
    }
  }
  .vignette-referentiel {
    height: 170px;
    width: 110px;
    float: left;
    margin-right: 10px;
    margin-top: 10px;
    img {
      height: auto;
      width: 100%;
    }
  }
}
