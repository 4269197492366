@media only screen and (min-width: 992px) {

  .navbar-header {
    .logo {
      img {
        max-width: 130px;
        padding: 20px;
      }
    }
  }
  // Header statique
  .navbar-fixed-top {
    position: static;
  }
  .navbar-default {
    margin-left: auto;
    margin-right: auto;
  }
  // .main-container {
  //   padding-top: 0;
  // }
  // Alerte notification
  .block-cyim-sfd-header-alert-block {
    .alert {
      margin-bottom: 0;
    }
  }
  // Fil d'arine
  .breadcrumb {
    margin-top: -30px;
    padding-bottom: 4px;
  }
  // Titre pages
  h1.page-header {
    margin: 30px 0 15px;
  }

  // Menu

  /*Zone teasers rubriques*/
  .view-article-une-home-commun,
  .view-teasera-home-commun {
    padding: 15px 0;
  }
  h1.page-header {
    span {
      font-size: 19.4px;
      &::before {
        bottom: -50px;
      }
    }
  }
  // Contact
  .webform-client-form-324 {
    .webform-component--adresse-sfd {
      float: left;
      width: 25%;
    }
    .form-inline .form-control {}
    .webform-container-inline {
      &.webform-component-textarea {
        .form-textarea-wrapper {
          display: block;
        }
      }
    }
    .formulaire_sfd {
      width: 65%;
      display: inline-block;
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1023px) {
  .view-teasera-home-commun {
    .views-field-title {
      h3 {
        &.field-content {
          margin-top: 20px;
        }
      }
    }
  }

}

@media only screen and (min-width: 992px) {
  .footer {
    #block-block-6 {
      a,
      p {
        &.ffrd-link {
          width: 90px;
          height: 49px;
        }
        &.ffd-link {
          width: 90px;
          height: 49px;
        }
        &.sfe-link {
          width: 90px;
          height: 43px;
        }
      }
    }
  }


}
