@media only screen and (min-width: 980px) {
  // Recherche Block
  #search-block-form {
    .form-text {
      display: table-cell;
      color: #b8b8b8;
      background: $white;
      border: 1px solid #b8b8b8;
      // border-top-left-radius: 20px;
      // border-bottom-left-radius: 20px;
      &::-webkit-input-placeholder {
        color: $gray-light;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $gray-light;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray-light;
      }
      &:-ms-input-placeholder {
        color: $gray-light;
      }
    }
  }
  .navbar-header {
    .logo {
      img {
        max-width: 120px;
        padding: 5px;
      }
    }
  }
  .slider-accueil {
    margin-top: 0;
  }
  #block-system-user-menu {
    position: relative;
    margin-top: 4px;
    width: auto;
    left: 30px;
  }
  #block-tb-megamenu-main-menu {
    margin-left: -15px;
    margin-right: -15px;
  }
  #search-block-form {
    position: absolute;
    max-width: 360px;
    right: 0;
    top: 5px;
  }
  // Menu principal *
  .mega-dropdown-menu {
    .tb-megamenu-column-inner .level-1 {
      margin-top: 1em;
    }
    .tb-megamenu-submenu .tb-megamenu-column-inner .level-2 {
      margin-bottom: 1em;
      li.level-3 {
        padding: 4px;
        cursor: pointer;
      }
    }
  }
  .tb-megamenu {
    .mega-nav .mega-group > .mega-group-title,
    .tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title {
      text-transform: none !important;
    }
    .mega-nav > li {
      padding-bottom: 15px;
    }
    .dropdown-menu .mega-nav > li.level_1-no_child {
      padding-bottom: 40px;
    }
    .nav > li > a {
      font-size: 100%;
    }
  }
  /* menu principal ********************************************************/
  .tb-megamenu .mega-group-ct,
  .tb-megamenu-subnav.level-2 {
    display: block;
  }
  .level-1.niv1-lexique {
    width: 100px;
  }
  /* Niveau 1 Presse */
  .tb-megamenu {
    .menu-presse.level-1 {
      a {
        background: #a88e5c !important;
        border-radius: 10px 10px 0 0;
        color: $white !important;
        &:hover {
          background: $red !important;
          border-radius: 10px 10px 0 0;
          color: $white;
        }
      }
      .dropdown-menu {
        display: none;
      }
    }
    background: transparent;
    .dropdown-menu {}
  }
  ul {
    &.tb-megamenu-nav li {
      &.niv1-publications {
        border: none;
      }
    }
    &.tb-megamenu-subnav li {
      height: inherit;
      background: inherit;
      border: inherit;
    }
    &.tb-megamenu-nav li a {
      font-family: "Raleway", sans-serif;
      font-weight: 500;
      line-height: 15px;
      font-size: 140%;
    }
    &.tb-megamenu-subnav li a {
      text-transform: inherit;
      height: inherit;
      line-height: inherit;
    }
  }
  .tb-megamenu {
    .dropdown-menu .mega-nav > li:first-child > a,
    .mega-nav > li:first-child > a {
      padding-left: 10px;
    }
    a {
      &:link,
      &:visited {
        text-decoration: none;
      }
    }
    .nav {
      > {
        li > a {
          font-weight: normal;
          color: #595959 !important;
          border: inherit;
          padding: 14px 12px;
          font-size: 90%;
        }
        .active > a {
          background: $red;
          color: $white !important;
          border-radius: 10px 10px 0 0;
          &:focus {
            background: $red;
            color: #595959 !important;
            border-radius: 10px 10px 0 0;
          }
        }
        li > a:hover {
          background: $red;
          color: $white !important;
          border-radius: 10px 10px 0 0;
        }
      }
      li.dropdown {
        &.active > .dropdown-toggle,
        &.open.active > .dropdown-toggle {
          background: $red;
          color: $white !important;
          border-radius: 10px 10px 0 0;
        }
        &.active > .dropdown-toggle,
        &.open.active > .dropdown-toggle {
          background: #595959;
        }
      }
      > .active > a {
        background: #595959;
        &:focus {
          background: #595959;
        }
      }
    }
  }
  .medical .tb-megamenu .nav {
    > .active > a {
      background-color: #1badc7;
      &:focus {
        background-color: #1badc7;
      }
    }
    li.dropdown {
      &.active > .dropdown-toggle {
        background-color: #1badc7;
      }
      &.open.active > .dropdown-toggle {
        background: $red;
      }
    }
  }
  .paramedical .tb-megamenu .nav {
    > .active > a {
      background-color: #f16804;
      &:focus {
        background-color: #f16804;
      }
    }
    li.dropdown {
      &.active > .dropdown-toggle {
        background-color: #f16804;
      }
      &.open.active > .dropdown-toggle {
        background: $red;
      }
    }
  }
  .mediatheque .tb-megamenu .nav,
  .ressources .tb-megamenu .nav {
    > .active > a {
      background-color: #595959;
      &:focus {
        background-color: #595959;
      }
    }
    li.dropdown {
      &.active > .dropdown-toggle {
        background-color: #595959;
      }
      &.open.active > .dropdown-toggle {
        background: $red;
      }
    }
  }
  .tb-megamenu-nav li.active .presse {
    background: #a88e5c;
  }
  .tb-megamenu .nav > {
    .active > a:hover,
    li.dropdown.open.active > a:hover {
      background: $red;
      color: $white !important;
      border-radius: 10px 10px 0 0;
    }
  }
  ul.tb-megamenu-nav li.level-1.open a {
    background: $red;
    color: $white !important;
    border-radius: 10px 10px 0 0;
  }
  .level-2 a {
    background: inherit !important;
  }
  .tb-megamenu {
    a {
      &:focus,
      &:hover {
        background: $red;
        color: $white !important;
      }
    }
    .dropdown-menu .active > a {
      background: $red;
      color: $white !important;
      &:hover {
        background: $red;
        color: $white !important;
      }
    }
    .nav li.dropdown.open > .dropdown-toggle {
      background: $red;
      color: $white !important;
    }
    .dropdown-menu {
      .active .mega-nav .mega-group > .mega-group-title,
      .mega-nav .mega-group > .mega-group-title,
      li.level-2 > a {
        font-weight: bold;
        color: #e9ab04 !important;
        font-family: "Raleway", sans-serif;
        font-size: 120%;
      }
    }
    .mega-nav .mega-group > .mega-group-title {
      font-weight: bold;
      color: #e9ab04 !important;
      font-family: "Raleway", sans-serif;
      font-size: 120%;
    }
  }
  .tb-megamenu-nav li.level-1.open li.level-2 a {
    font-weight: bold;
    color: #e9ab04 !important;
    font-family: "Raleway", sans-serif;
    font-size: 120%;
  }
  .tb-megamenu .dropdown-menu li.level-2 > a:hover {
    color: #e9ab04 !important;
  }
  #tb-megamenu-column-6 {
    margin-left: 0;
  }
  .tb-megamenu-nav li.level-1 li.level-2 li.level-3 a {
    font-family: "Raleway", sans-serif;
    font-size: 90%;
    // background: url(//www.sfdiabete.org/sites/sfd.prod/themes/tb_sfd/img/puce-menu-level3.png) no-repeat left 7px !important;
    color: $white !important;
    font-weight: 500;
    padding-top: 0;
    &::before {
      content:'•';
      font-size: 20px;
      font-weight: 'bold';
      color: #e9ab04;
      top: 3px;
      position: relative;
    }
  }
  .tb-megamenu {
    .nav li.dropdown {
       &.active > .dropdown-toggle .caret,
      > .dropdown-toggle .caret {
        display: none;
      }
      &.open {
         > .dropdown-toggle .caret,
        .caret {
          display: none;
        }
        a {
          &:focus .caret,
          &:hover .caret {
            display: none;
          }
        }
        &.active {
           > .dropdown-toggle .caret,
          .caret {
            display: none;
          }
        }
      }
    }
    .dropdown-menu li > a {
      border-top: none;
      padding: 5px 20px;
    }
    .nav-collapse {
      margin-left: 160px;
      margin-top: -43px;
      .dropdown-menu {
        background: $red;
        border: none;
        box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        height: 400px;
        opacity: .9;
      }
    }
    .dropdown-menu .mega-nav > li a,
    .mega-nav > li a {
      margin-left: 0;
      padding: 0 0 5px 10px;
    }
    .nav > li.home-link {
      > a {
        background: url("../img/home-picto.png") no-repeat scroll left center rgba(0, 0, 0, 0);
        text-indent: -5000px;
        width: 45px;
        background-position-x: -4px;
        &:hover {
          background: url("../img/home-picto-hover.png") no-repeat left center;
          text-indent: -5000px;
          width: 45px;
          background-position-x: -4px;
        }
      }
      &.active > a {
        background: url("../img/home-picto-hover.png") no-repeat left center;
        text-indent: -5000px;
        width: 35px;
        background-position-x: -4px;
      }
    }
  }
  .tb-megamenu-submenu {
    width: 100%;
  }
  /* force pleine largeur sous-menu */
  .mega-dropdown-menu .tb-megamenu-submenu .tb-megamenu-column-inner .level-2 li.level-3.cadenas {
    // background: url("../img/puce-menu-level3.png") no-repeat 4px 10px !important;
    a {
      padding-right: 20px;
      display: inline;
      &.cadenas {
        color: $white;
        &::after {
          display: inline;
          font-family: FontAwesome;
          content: "\f023";
          color: #fff;
          font-size: 20px;
          position: relative;
          visibility: visible;
          margin-left: 10px;
        }
      }
    }
  }
  /* cache le niveau 4 et icone fleche associee au niveau*/
  .tb-megamenu-item.level-3 {
    a:after {
      visibility: hidden;
    }
    .nav-child {
      display: none !important;
      opacity: 0 !important;
      .tb-megamenu.row-fluid {
        display: none !important;
        visibility: hidden;
      }
    }
    &.animating .nav-child .tb-megamenu.row-fluid {
      display: none !important;
      visibility: hidden;
    }
    .nav-child a {
      display: none !important;
    }
  }
  .tb-megamenu-submenu.dropdown-menu.mega-dropdown-menu.nav-child {
    width: 100%;
  }
}
