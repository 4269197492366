/*Menu Outils et raccourcis*/
#block-block-5 {
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: white;
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 19%;
      line-height: 3;
      text-align: center;
      a {
        color: $white;
        font-size: 0;
        &:hover {
          opacity: .5;
          text-decoration: none;
        }
        span {
          font-size: 26px;
          display: inline-block;
          line-height: 1.7;
          color: $red;
        }
      }
    }
  }
}
