body {
  &.mediatheque-videos {
    display: table;
    font-family: $font-family-sans-serif;
  }
}

// Couleurs Onglet Médiathèque
.mediatheque-videos {
  .tb-megamenu-nav {
    li[data-id="931"] a {
      background-color: #595959;
      color: #fff !important;
      border-radius: 10px 10px 0 0;
    }
  }
}

// Rubrique vidéothèque
.sub-mediatheque {
  iframe {
    max-width: 440px;
    max-height: 247.5px;
  }
  .field-name-field-documents {
    clear: both;
    margin-bottom: 30px;
  }
}
// Médiathèque CYIM
.mediatheque-videos {
  .breadcrumb {
    display: none;
  }
  footer {
    display: block;
    border-top: 10px solid $brand-primary;
  }
  .contenu.col-xs-12 {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: -3px !important;
    margin-left: 15px !important;
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .row {
    display: block;
  }
  .cyim-media-view-content {
    padding: 0;
  }
  .header {
    padding: 0;
  }
  .col,
  .row > * {
    display: block;
    vertical-align: inherit;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .leftPub,
  .pub {
    display: none;
  }
  .bgBox {
    width: 100%;
    .footer {
      display: none;
    }
  }
  #search-block-form {
    margin-right: 15px !important;
  }
  #block-block-5 ul li {
    width: 18%;
  }
  .btnBack {
    width: auto;
  }
}

@media (max-width: 767px) {
  .mediatheque,
  .mediatheque-videos {
    .searchArea {
      .txt {
        height: 35px;
        font-size: 1.4rem;
      }
      .btn {
        height: 35px;
      }
    }
  }
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .header {
    height: auto;
  }
}

@media (min-width: 980px) {
  .mediatheque,
  .mediatheque-videos {
    .tb-megamenu .nav-collapse .dropdown-menu {
        margin-top: -3px;
      }
    }
}
