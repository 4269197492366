/* petites annonces  */

.view-annonces-de-postes {
	.view-header {
		border: 1px solid #E9E9E9;
		padding: 20px 20px 0 20px;
		margin: 0 auto 35px auto;
		width: 76%;
		background: #E9E9E9;
	}

  .views-row {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px dashed #ddd;
    p.date-title {
      color: #e4a704;
      font-size: 130%;
      font-weight: 500;
      font-family: 'Raleway', sans-serif;
      letter-spacing: normal;
      margin-bottom: 10px;
    }
  }
  .views-field-title a {
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  .views-field-field-statut-annonce span {
    color: #cccccc;
    padding: 2px 0px;
    &.Urgent {
      background-color: #96132b;
      color: #ffffff;
      padding: 2px 10px;
    }
    &.Pourvue {
      background-color: #f16804;
      color: #ffffff;
      padding: 2px 10px;
    }
    &.Mise-à-jour {
      background-color: #1badc7;
      color: #ffffff;
      padding: 2px 10px;
    }
  }
}

.node-type-annonce-de-poste {
  .field-name-field-date-parution {
    .field-label {
      font-weight: normal;
    }
    .field-item {
      font-weight: bold;
    }
  }
  .field-name-field-statut-annonce .field-item {
    a {
      color: #cccccc;
      padding: 6px 15px;
      text-decoration: none;
      display: inline-block;
      margin-bottom: 10px;
    }
    &.Urgent a {
      background-color: #96132b;
      color: #ffffff;
    }
    &.Pourvue a {
      background-color: #f16804;
      color: #ffffff;
    }
    &.Mise a {
      background-color: #1badc7;
      color: #ffffff;
    }
  }
  .field-name-field-tags.field-type-taxonomy-term-reference {
    .field-items {
      padding-top: 10px 0;
      clear: both;
      .field-item {
        background: #e9e9e9;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 20px;
        margin-right: 15px;
      }
    }
    .field-item a {
      color: #333333;
      text-decoration: none;
      font-weight: bold;
      font-size: 90%;
      &:hover {
        text-decoration: none;
        text-decoration: none;
        color: #555;
      }
    }
  }
}
