.modal-open {
  overflow: hidden;
  height: 100%;
}

#contentWrapper {
  display: unset;
}

/***
* Reset CSS
*/

#tarteaucitronRoot {
  div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    /*background: initial;*/
    text-align: initial;
    text-shadow: initial;
  }
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote {
    &:before, &:after {
      content: '';
    }
  }
  q {
    &:before, &:after {
      content: '';
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  // a:focus, button:focus {
  //   /*outline: 2rem solid $brand-danger;*/
  // }
}

/* HTML5 display-role reset for older browsers */

/***
 * Better scroll management
 */

#tarteaucitronMainLineOffset {
  margin-top: 0;
}

#tarteaucitronServices {
  margin-top: 21rem;
  box-shadow: 0 0 .35rem $gray-light;
  &::-webkit-scrollbar {
    width: 5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-lighter;
    outline: 0 solid $gray;
  }
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine {
    .tarteaucitronName {
      width: 90%;
    }
    .tarteaucitronAsk {
      float: left;
      margin: 1rem 1.5rem 5rem;
    }
  }
}

@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
    background: white;
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    top: 0;
    width: 100%;
  }
  #tarteaucitron {
    background: white;
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    top: 0;
    width: 100%;
    .tarteaucitronBorder {
      border: 0;
    }
  }
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left;
  }
  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center;
    button {
      margin-bottom: .5rem;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0;
    left: 0;
    margin: 0 5%;
    max-height: 80%;
    width: 90%;
  }
}

/***
 * Common value
 */

#tarteaucitron * {
  zoom: 1;
}

#tarteaucitron {
  .clear {
    clear: both;
  }
  a {
    color: $gray;
    font-size: 1.1rem;
    font-weight: 700;
    text-decoration: none;
  }
  strong {
    font-size: 2.2rem;
    font-weight: 500;
  }
  ul {
    padding: 0;
  }
  .tarteaucitronH1, .tarteaucitronH2, .tarteaucitronH3, .tarteaucitronH4, .tarteaucitronH5, .tarteaucitronH6 {
    display: block;
  }
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860;
  z-index: 2147483647;
  .tarteaucitronBorder {
    background: white;
    // border: 2px solid $gray-darker;
    border-top: 0;
    height: auto;
    overflow: auto;
  }
}

#tarteaucitronRoot button {
  background: $gray-darker;
  border: 0;
  color: white;
}

#tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong, #tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
  color: white;
}

.cookie-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/***
 * Root div added just before </body>
 */

#tarteaucitronRoot {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  * {
    box-sizing: initial;
    color: $gray;
    font-family: sans-serif;
    font-size: 1.1rem;
    line-height: normal;
    vertical-align: initial;
  }
  .tarteaucitronH1 {
    font-size: 1.5rem;
    text-align: center;
    color: white;
    margin: 1.5rem 0 2.8rem;
  }
  .tarteaucitronH2 {
    display: inline-block;
    margin: 1.2rem 0 0 1rem;
    color: white;
  }
  #tarteaucitron {
    left: 0;
    right: 0;
    margin: auto;
    width: 50%;
    text-align: right;
  }
  #tarteaucitronBack {
    background: $gray-lighter;
  }
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0;
}

/***
 * Control panel
 */

#tarteaucitronBack {
  background: white;
  display: none;
  height: 100%;
  left: 0;
  opacity: .7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie {
  background: $gray-darker;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
  padding: .4rem 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70;
}

.tarteaucitronSelfLink, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  background: rgba(51, 51, 51, 0.07);
}

.tarteaucitronSelfLink {
  text-align: center;
  display: block;
  padding: .7rem;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
  width: 100%;
  box-sizing: border-box;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
  color: white;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  margin: 2rem 0 0;
  padding: .5rem 2rem;
  text-align: left;
  width: auto;
  background: $gray-darker;
}

#tarteaucitron {
  #tarteaucitronServices .tarteaucitronTitle button, #tarteaucitronInfo {
    color: white;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 2rem 0 0;
    padding: .5rem 2rem;
    text-align: left;
    width: auto;
    background: $gray-darker;
  }
  #tarteaucitronServices {
    .tarteaucitronDetails {
      color: white;
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 700;
      margin: 2rem 0 0;
      padding: .5rem 2rem;
      text-align: left;
      width: auto;
      background: $gray-darker;
    }
    .tarteaucitronMainLine .tarteaucitronName a, .tarteaucitronTitle a {
      color: white;
      font-weight: 500;
    }
    .tarteaucitronMainLine .tarteaucitronName a:hover, .tarteaucitronTitle a:hover {
      text-decoration: none;
    }
    .tarteaucitronMainLine .tarteaucitronName a {
      font-size: 2.2rem;
    }
    .tarteaucitronTitle a {
      font-size: 1.4rem;
    }
  }
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
  padding: .5rem 1rem;
  margin: 0;
}

#tarteaucitron {
  #tarteaucitronInfo, #tarteaucitronServices .tarteaucitronDetails {
    color: white;
    display: none;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 0;
    max-width: 270;
    padding: 2rem;
    position: absolute;
    z-index: 2147483647;
  }
  #tarteaucitronInfo a {
    color: white;
    text-decoration: underline;
  }
  #tarteaucitronServices {
    .tarteaucitronLine {
      &:hover {
        background: rgba(51, 51, 51, 0.2);
      }
      background: rgba(51, 51, 51, 0.1);
      // border-left: 5px solid transparent;
      margin: 0;
      overflow: hidden;
      padding: 1.5rem .5rem;
    }
    .tarteaucitronMainLine {
      background: $gray-darker;
      // border: 3px solid $gray-darker;
      // border-left: 9px solid $gray-darker;
      // border-top: 5px solid $gray-darker;
      margin-bottom: 0;
      position: relative;
      &:hover {
        background: $gray-darker;
      }
      .tarteaucitronName {
        margin-left: 1.5rem;
        margin-top: .2rem;
        button {
          color: white;
        }
      }
      .tarteaucitronAsk {
        margin-top: 0;
      }
    }
    .tarteaucitronLine {
      .tarteaucitronName {
        display: inline-block;
        float: left;
        margin-left: 1rem;
        text-align: left;
        width: 50%;
        a:hover {
          text-decoration: underline;
        }
      }
      .tarteaucitronAsk {
        display: inline-block;
        float: right;
        margin: .7rem 1.5rem 0;
        text-align: right;
        .tarteaucitronAllow, .tarteaucitronDeny {
          background: $gray;
          border-radius: 4px;
          color: white;
          cursor: pointer;
          display: inline-block;
          padding: .6rem 1rem;
          text-align: center;
          text-decoration: none;
          width: auto;
          border: 0;

        }
        .tarteaucitronAllow {
           background: $brand-primary;
          // background: white !important;
          // color: black !important;
        }
        .tarteaucitronDeny {
         background: $brand-success;

        }
      }
    }
  }
}

.tac_activate .tarteaucitronAllow {
  background: $gray;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: inline-block;
  padding: .6rem 1rem;
  text-align: center;
  text-decoration: none;
  width: auto;
  border: 0;
}

#tarteaucitron {
  #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
    color: $gray-darker;
    font-size: 12px;
  }
  .tarteaucitronH3 {
    font-size: 1.4rem;
  }
  #tarteaucitronMainLineOffset .tarteaucitronName {
    width: auto;
    margin-left: 0;
    font-size: 1.4rem;
  }
}

/***
 * Big alert
 */

.tarteaucitronAlertBigTop {
  top: 0;
}

.tarteaucitronAlertBigBottom {
  bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  background: $gray-darker;
  color: white;
  display: none;
  font-size: 1.4rem;
  left: 0;
  padding: .5rem;
  position: fixed;
  text-align: center;
  box-sizing: content-box;
  z-index: 2147483645;
  text-align: center;
  padding: 1rem;
  margin: auto;
  width: 100%;
}

#tarteaucitronAlertBig {
  #tarteaucitronDisclaimerAlert {
    font: 15px verdana;
    color: white;
    strong {
      font: 15px verdana;
      color: white;
      font-weight: 700;
    }
  }
  #tarteaucitronPrivacyUrl {
    cursor: pointer;
  }
 #tarteaucitronPersonalize {
    background: $brand-primary !important;
    color: $legend-border-color;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    padding: .5rem 1rem;
    text-decoration: none;
    margin-left: .7rem;

  }
}

#tarteaucitron #tarteaucitronPrivacyUrl {
  background: $brand-success;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  padding: .5rem 1rem;
  text-decoration: none;
  margin-left: .7rem;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl {
  background: white;
  color: $gray-darker;
  font-size: 1.4rem;
  margin-bottom: .3rem;
  margin-left: .7rem;
  padding: .4rem 1rem;
}

#tarteaucitronPercentage {
  background: $brand-success;
  box-shadow: 0 0 2px white, 0 1px 2px $gray;
  height: 5px;
  left: 0;
  position: fixed;
  width: 0;
  z-index: 2147483644;
}

/***
 * Small alert
 */

.tarteaucitronAlertSmallTop, .tarteaucitronAlertSmallBottom {
  bottom: 0;
}

#tarteaucitronAlertSmall {
  background: $gray-darker;
  display: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 2147483646;
  #tarteaucitronManager {
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 11px;
    padding: 8px 10 8px;
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
    #tarteaucitronDot {
      background-color: $gray;
      border-radius: 5px;
      display: block;
      height: 8px;
      margin-bottom: 1px;
      margin-top: 5px;
      overflow: hidden;
      width: 100%;
      #tarteaucitronDotGreen, #tarteaucitronDotYellow, #tarteaucitronDotRed {
        display: block;
        float: left;
        height: 100%;
        width: 0%;
      }
      #tarteaucitronDotGreen {
        background-color: $brand-success;
      }
      #tarteaucitronDotYellow {
        background-color: $brand-warning;
      }
      #tarteaucitronDotRed {
        background-color: $brand-danger;
      }
    }
  }
  #tarteaucitronCookiesNumber {
    background: rgba(255, 255, 255, 0.2);
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 30;
    padding: 0 10;
    vertical-align: top;
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
  #tarteaucitronCookiesListContainer {
    display: none;
    max-height: 70%;
    max-width: 500;
    position: fixed;
    right: 0;
    width: 100%;
    #tarteaucitronCookiesList {
      background: white;
      border: 2px solid $gray-darker;
      color: $gray-darker;
      font-size: 11px;
      height: auto;
      overflow: auto;
      text-align: left;
      strong {
        color: $gray-darker;
      }
    }
    #tarteaucitronCookiesTitle {
      background: $gray-darker;
      margin-top: 21px;
      padding: 13px 0 9px 13px;
      text-align: left;
      strong {
        color: white;
        font-size: 16px;
      }
    }
    #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
      background: rgba(51, 51, 51, 0.1);
      padding: 7px 5px 10;
      word-wrap: break-word;
      &:hover {
        background: rgba(51, 51, 51, 0.2);
      }
      a {
        color: $gray-darker;
        text-decoration: none;
      }
      .tarteaucitronCookiesListLeft {
        display: inline-block;
        width: 50%;
        a strong {
          color: $brand-danger;
        }
      }
      .tarteaucitronCookiesListRight {
        color: $gray-darker;
        display: inline-block;
        font-size: 11px;
        margin-left: 10%;
        vertical-align: top;
        width: 30%;
      }
    }
  }
}

/***
 * Fallback activate link
 */

.tac_activate {
  background: $gray-darker;
  color: white;
  display: table;
  font-size: 12px;
  height: 100%;
  line-height: initial;
  margin: auto;
  text-align: center;
  width: 100%;
}

.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.tac_activate .tac_float {
  strong {
    color: white;
  }
  .tarteaucitronAllow {
    background-color: $brand-success;
    display: inline-block;
  }
}

/***
 * CSS for services
 */

ins {
  &.ferank-publicite, &.adsbygoogle {
    text-decoration: none;
  }
}

.amazon_product {
  height: 240;
  width: 120;
}

// .tarteaucitronIsAllowed .tarteaucitronDeny, .tarteaucitronIsDenied .tarteaucitronAllow {
//   opacity: 0.4;
// }

.tarteaucitronIsAllowed .tarteaucitronAllow, .tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1;
}

// .tarteaucitronLine .tarteaucitronAllow {
//   opacity: 0.4;
// }

#tarteaucitronInfo {
  display: block;
  position: initial;
  text-align: center;
  max-width: 80%;
  padding: 15px 0;
  margin: -10 auto 40;
  font-size: 1em;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: $gray;
}

.tarteaucitronSelfLink {
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 13px;
  display: block;
  text-shadow: 0 0 14px white;
  text-transform: uppercase;
}

.tarteaucitronMainLine .tarteaucitronH2 {
  font-size: 1.2em;
  margin-top: 4px;
}

.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px;
}

// onclick="tarteaucitron.userInterface.respondAll(false);