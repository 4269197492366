@media only screen and (min-width: 480px) {
  /*Menu User*/
  #block-system-user-menu {
    margin-left: 30px;
    .nav {
      & > li {
        a {
          &:hover {}
        }
        &.first {
          a {
            background: #96132b url("../img/user-menu-button.png") no-repeat left top;
            padding: 0 10px 0 50px;
            line-height: 37px;
          }
        }
        &.last {}
        &.first,
        &.first.open {
           > a:hover,
          > a:focus {
            background: #96132b url("../img/user-menu-button.png") no-repeat left top;
          }
        }
      }
    }
  }
  .mediatheque,
  .mediatheque-videos {
    #block-system-user-menu .nav > li.first a {
      line-height: 38px;
    }
  }
  .breadcrumb {
    margin-left: 12%;
    margin-top: -27px;
    position: absolute;
  }
  #block-block-3,
  #block-block-4 {
    ul {
      li {
        padding-bottom: 11px;
        width: 19.90%;
        font-size: 11px;
      }
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {}
