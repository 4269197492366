#permissions {
  td {
    &.module {
      font-weight: bold;
    }
    &.permission {
      padding-left: 1.5em;
      /* LTR */
    }
  }
  tr {
    &.odd .form-item, &.even .form-item {
      white-space: normal;
    }
  }
}

#user-admin-settings fieldset .fieldset-description {
  font-size: 0.85em;
  padding-bottom: .5em;
}

/**
 * Override default textfield float to put the "Add role" button next to
 * the input textfield.
 */

#user-admin-roles {
  td.edit-name {
    clear: both;
  }
  .form-item-name {
    float: left;
    /* LTR */
    margin-right: 1em;
    /* LTR */
  }
}

/**
 * Password strength indicator.
 */

.password-strength {
  width: 17em;
  float: right;
  /* LTR */
  margin-top: 1.4em;
}

.password-strength-title {
  display: inline;
}

.password-strength-text {
  float: right;
  /* LTR */
  font-weight: bold;
}

.password-indicator {
  background-color: #C4C4C4;
  height: 0.3em;
  width: 100%;
  div {
    height: 100%;
    width: 0%;
    background-color: #47C965;
  }
}

input {
  &.password-confirm, &.password-field {
    width: 16em;
    margin-bottom: 0.4em;
  }
}

div {
  &.password-confirm {
    float: right;
    /* LTR */
    margin-top: 1.5em;
    visibility: hidden;
    width: 17em;
  }
  &.form-item div.password-suggestions {
    padding: 0.2em 0.5em;
    margin: 0.7em 0;
    width: 38.5em;
    border: 1px solid #B4B4B4;
  }
  &.password-suggestions ul {
    margin-bottom: 0;
  }
}

.confirm-parent, .password-parent {
  clear: left;
  /* LTR */
  margin: 0;
  width: 36.3em;
}

/* Generated by user.module but used by profile.module: */

.profile {
  clear: both;
  margin: 1em 0;
  .user-picture {
    float: right;
    /* LTR */
    margin: 0 1em 1em 0;
    /* LTR */
  }
  h3 {
    border-bottom: 1px solid #ccc;
  }
  dl {
    margin: 0 0 1.5em 0;
  }
  dt {
    margin: 0 0 0.2em 0;
    font-weight: bold;
  }
  dd {
    margin: 0 0 1em 0;
  }
}

// Allocations

.block-page-user {
  padding: 20px;
  border: 2px solid #96132b;
  margin-bottom: 65px;
  h3 {
    color: #595959;
    margin-top: 0;
    border-bottom: 1px solid #ccc;
  }
  a, input[type="submit"] {
    background-color: #a32e3d !important;
    text-shadow: none !important;
    font-weight: bold;
    font-size: 14px;
    padding: 8px 12px;
    margin: 0;
    background: none;
    border: 0;
    border-radius: 4px !important;
    color: #FFF !important;
    text-decoration: none;
    cursor: pointer;
    margin: 5px;
    line-height: 30px;
  }
  p {
    margin: 0 0 20px 0;
    margin: 20px 0 0 0;
  }
}
