/*Vue articles QDM*/

.view-articles-qdm .item-list {
  h3 {
    margin-bottom: 10px;
    color: #e4a704;
    font-size: 130%;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    letter-spacing: normal;
  }
  ul li div {
    span a {
      text-decoration: none;
      font-weight: bold;
      font-size: 1.1em;
    }
    &.views-field-field-auteur {
      span.views-label-field-auteur {
        display: inline-block;
        float: left;
        margin-right: 8px;
      }
      .field-content {
        font-weight: bold;
      }
    }
  }
}

.region-content .view-articles-qdm .item-list .pager {
  width: 28%;
  margin: auto;
  background: none;
}

.view-articles-qdm .pager li.pager-first a {
  width: 75px;
}

.pager li {
  &.pager-previous a, &.pager-next a, &.pager-last a {
    width: 75px;
  }
}

.view-articles-qdm .pager li.pager-next {
  right: 75px;
}

.node-article-qdm .field-type-text-with-summary {
  padding-top: 15px;
}

.node-type-article-qdm {
.field-type-taxonomy-term-reference {
    .field-items {
      padding-top: 10px 0;
      clear: both;
      .field-item {
        background: #e9e9e9;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 20px;
        margin-right: 15px;
      }
    }
    .field-item a {
      color: #333333;
      text-decoration: none;
      font-weight: bold;
      font-size: 90%;
      &:hover {
        text-decoration: none;
        text-decoration: none;
        color: #555;
      }
    }
  }
  .content {
    .field-name-field-auteur {
      .field-label {
        font-weight: normal;
      }
      .field-items .field-item {
        font-weight: bold;
      }
    }
    .field-name-field-date-parution {
      .field-label {
        font-weight: normal;
      }
      .date-display-single {
        font-weight: bold;
      }
    }
    .field-name-field-qdm-numero {
      .field-label {
        font-weight: normal;
      }
      .field-item {
        font-weight: bold;
      }
    }
    .field-name-field-source-pdf {
      img {
        margin-right: 3px;
      }
      a {
        font-weight: bold;
        text-decoration: none;
        line-height: 16px;
        vertical-align: middle;
      }
    }
  }
}
