/*Zone teasers rubriques*/
.view-article-une-home-commun,
.view-teasera-home-commun {
  padding: 15px 0 50px 0;
}
/*Teaser Home Commun et medical/paramedical*/
.view-teasera-home-commun {
  .views-field-field-titre {
    width: 100%;
    color: #96132b;
    font-weight: 600;
    margin: 15px 0;
    background: url("../img/title-fleche-teaser.png") no-repeat 40px bottom;
    display: inline-block;
    border-radius: 20px;
    padding-bottom: 26px;
  }
  &.view-display-id-block_1 .views-field-field-titre,
  &.view-display-id-block_4 .views-field-field-titre {
    width: 85%;
  }
  &.view-display-id-block_3 .views-field-field-titre,
  &.view-display-id-block_5 .views-field-field-titre,
  &.view-display-id-block_6 .views-field-field-titre {
    width: 100%;
  }
  .views-field-field-titre .field-content {
    background-color: #ffffff;
    padding: 8px 0 8px 20px;
    display: inline-block;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }
  .views-field-field-image img {
    width: 100%;
  }
  .views-field-title {
    h3 {
      &.field-content {
        margin-top: 0;
        color: #231f20;
        a {
          color: #231f20;
          text-decoration: none;
        }
      }
    }
  }
  .views-field-body {
    width: 100%;
  }
  .views-field-field-lien-vers-article {
    .field-content {
      text-align: right;
    }
    a {
      background-color: #96132b;
      color: #ffffff;
      padding: 5px 20px;
      border-radius: 15px;
      text-align: right;
      display: inline-block;
      text-decoration: none;
      font-size: 80%;
    }
  }
}
#block-views-teasera-home-commun-block-3, #block-views-teasera-home-commun-block-4 {
  .views-field-body .field-content {
    > div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    }
    .container-iframe {
      overflow: hidden;
    }
    iframe, video {
      position: absolute;
      border: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.view-id-article_une_home_commun {
  .views-field-title {
    h3 {
      &.field-content {
        margin-top: 0;
      }
    }
  }
}
/*Teaser Home specifique Medical*/
.home-medical .view-teasera-home-commun {
  .views-field-field-titre h2 {
    color: #1badc7;
  }
  .views-field-field-lien-vers-article a {
    background-color: #1badc7;
  }
}
/*Teaser Home specifique Paramedical*/
.home-paramedical .view-teasera-home-commun {
  .views-field-field-titre h2 {
    color: #f16804;
  }
  .views-field-field-lien-vers-article a {
    background-color: #f16804;
  }
}
/*Article à la Une*/
#block-views-article-une-home-commun-block {
  .view-header h2 {
    color: #96132b;
    font-weight: 600;
    margin: 15px 0;
    background: url("../img/title-fleche-teaser.png") no-repeat 40px bottom;
    display: inline-block;
    border-radius: 20px;
    padding-bottom: 26px;
    width: 100%;
    span {
      background-color: #ffffff;
      padding: 8px 0 8px 20px;
      display: inline-block;
      border-radius: 20px;
      width: 100%;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
  .view-content {
    width: 100%;
  }
  .views-field-title h3 a {
    color: #231f20;
    text-decoration: none;
  }
  .views-field-view-node {
    .field-content {
      text-align: right;
      width: 100%;
      display: inline-block;
    }
    a {
      background-color: #96132b;
      color: #ffffff;
      padding: 5px 20px;
      border-radius: 15px;
      text-align: right;
      display: inline-block;
      text-decoration: none;
      font-size: 80%;
    }
  }
}
.footer-home {
  iframe,
  video {
    width: 100%;
  }
  background-color: #e9e9e9;
  margin-top: 3em;
  // padding-bottom: 2em;
  .views-field-field-image-picto {
    img {
      text-align: center;
      display: inline-block;
    }
  }
  .views-field-title {
    h2 {
      a {
        text-decoration: none;
        color: #1badc7;
      }
    }
  }
}
.home-medical {
  .footer-home {
    .views-field-title {
      h2 {
        a {
          color: #1badc7;
        }
      }
    }
  }
}
.home-paramedical {
  .footer-home {
    .views-field-title {
      h2 {
        a {
          text-decoration: none;
          color: #f16804;
        }
      }
    }
    // @media only screen and (min-width: 480px) {
    //   .view-dom-id-b24c7e4f7f50ae1e364f6815a5d2ad57 {
    //     .views-field-title {
    //       h2 {
    //         a {
    //           font-size: 11.7px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
