@import '_variables';
/* titres */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  margin-bottom: 10px;
}
h1 {
  color: #1badc7;
  font-size: 140%;
}
h2 {
  color: #96132b;
  font-size: 120%;
  font-weight: 500;
}
h3 {
  color: #e4a704;
  font-size: 110%;
  font-weight: 500;
}
h4 {
  color: #333333;
  font-size: 110%;
}
h5 {
  color: #96132b;
  font-size: 110%;
}
h6 {
  color: #e4a704;
  font-size: 105%;
}
a {
  // &:visited {
  //   color: #a32e3d;
  //   text-decoration: none;
  // }
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    font-weight: (bold,);
  }
}
p {
  text-align: justify;
  line-height: 1.3em;
  margin-bottom: 20px;
}
hr {
  color: #333333;
  height: 1px;
  margin: 15px 0;
  padding: 0;
  border: 0;
  background-color: #333333;
}
pre {
  overflow: auto;
  white-space: -moz-pre-wrap;
}
blockquote {
  font-style: italic;
}
/* images */
img {
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.region-content img {
  margin-right: 10px;
  border: 1px solid #ddd;
}
a img {
  border: none;
  outline: none;
  display: block;
  overflow: hidden;
}
/* fieldset */
fieldset {
  border: 1px solid #b8b8b8;
  padding: 15px;
}
legend {
  font-weight: bold;
  color: #a32e3d;
  padding: 0 5px;
}
/* Tables */
table {
  font-size: 90%;
  width: 100%;
  margin: auto;
  td {
    border: none;
    vertical-align: inherit;
  }
  th {
    border: none;
    vertical-align: inherit;
    background-color: #96132b;
    color: #fff;
    font-weight: bold;
    a {
      color: #fff;
    }
  }
  tr {
    &.odd {
      background: transparent;
    }
    &.even {
      background: #fff;
    }
  }
  td,
  th {
    padding: 5px;
  }
  caption {
    margin-bottom: 8px;
    color: #96132b;
    font-weight: bold;
  }
  thead {
    background-color: #4c2a56;
    color: #fff;
    font-weight: normal;
    line-height: 23px;
    tr th {
      padding-left: 3px;
      text-align: center;
      font-weight: bold;
      color: #fff;
      border: none;
    }
  }
  tbody tr {
    border-bottom: 1px solid #ccc;
    td {
      padding: 5px 3px;
      color: #444;
      text-align: left;
      vertical-align: top;
      a:hover {
        text-decoration: underline;
      }
    }
  }
  tr {
    &.even,
    &.odd {
      border-bottom: none;
    }
  }
}
.content table {
  margin-bottom: 15px;
}
table.noborder {
  td,
  th,
  tr {
    border: none;
  }
}
tr {
  &.even:hover td.active,
  &.odd:hover td.active,
  &:hover td {
    background: #ffffff;
  }
}
table p {
  margin: 0;
}
/* listes */
#block-system-main article {
  ol,
  ul {
    margin: 0 0 10px 15px;
    padding: 0;
  }
}
body.cke_show_borders {
  ol,
  ul {
    margin: 0 0 10px 15px;
    padding: 0;
  }
}

#block-system-main article ul li,
.colonne_droite ul li,
body.cke_show_borders ul li {
  background: transparent url("../img/puce1.png") no-repeat left 8px;
  list-style-type: none;
  padding-left: 11px;
  margin-bottom: 5px;
  list-style: none;
  font-size: 100%;
  // &::before {
  //   content: "•";
  //   color: #e9ab04;
  //   font-size: 20px;
  //   position: relative;
  //   right: 5px;
  //   top: 3px;
  // }
}
#block-system-mainul li ul li,
body.cke_show_borders ul li ul li,
colonne_droite ul li ul li {
  background: transparent url("../img/puce2.png") no-repeat left 8px;
}
#block-system-main article {
  .tabs ul li,
  ul.inline li {
    background: none;
  }
  ol {
    margin-left: 30px;
    li {
      margin-bottom: 7px;
      ul li {
        margin-top: 5px;
      }
    }
  }
  ul li ul li {
    margin-top: 5px;
  }
}
dl {
  dt {
    font-weight: bold;
    font-size: 100%;
  }
  dd {
    font-size: 90%;
  }
}
/* ckeditor */
// body {
//   &.cke_show_borders {
//     font-size: 80%;
//   }
//   &.cke_panel_frame {
//     p,
//     table {
//       font-size: 80%;
//     }
//   }
// }
/* bouton + d'infos */
.plusDinfos {
  padding: 4px 12px;
  text-decoration: none !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: url("../images/bg_button.png") repeat-x left top;
  color: #fff !important;
  height: 13px;
  &:hover {
    background: url("../images/bg_button_hover.png") repeat-x left top;
  }
}
.btn-default {
  background: #96132B;
  color: $white;
  border-color: #96132B;
  text-decoration: none;
}
.btn-primary {
    color: #fff;
    background-color: #96132B;
    border-color: #7f1025;
}
// ckeditor.css
.rteindent1 {
  margin-left: 40px;
}
.rteindent2 {
  margin-left: 80px;
}
.rteindent3 {
  margin-left: 120px;
}
.rteindent4 {
  margin-left: 160px;
}
// ckeditor.styles.js ====================================
// Ajout d'un fond
.alert {
  border: 0 none;
  border-radius: 0;
  min-height: 34px;
  margin-bottom: 22px;
  overflow: hidden;
  padding: 20px;
  position: relative;
  text-shadow: none;
  transition: opacity .3s linear;
}
// Ajout d'une bordure
.notification {
  border-width: 1px;
  border-style: solid;
  padding: 20px;
  margin: 0 0 10px;
}
.border-primary {
  border-color: $brand-primary;
}
.border-success {
  border-color: $brand-success;
}
.border-info {
  border-color: $brand-info;
}
.border-warning {
  border-color: $brand-warning;
}
.border-danger {
  border-color: $brand-danger;
}
