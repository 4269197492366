.liste-evenements-vue {
  width: 50%;
  float: left;
  z-index: 10;
  .item-list li {
    line-height: 2em;
    background-position: left 10px;
    a {
      font-weight: bold;
      text-decoration: none;
      font-size: 1.1em;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.node-type-evenement .field-name-field-documents .field-item {
  img {
    margin-right: 3px;
  }
  a {
    font-weight: bold;
    text-decoration: none;
    line-height: 16px;
    vertical-align: middle;
    &:hover {
      text-decoration: underline;
    }
  }
}
.node-evenement {
  .field-type-taxonomy-term-reference .field-item {
    display: inline-block;
    margin-right: 5px;
    margin-top: 15px;
    a {
      background-color: #e9e9e9;
      color: #333333;
      text-decoration: none;
      font-weight: bold;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 90%;
    }
    &.Medical a {
      background-color: #1badc7;
      color: #ffffff;
    }
    &.Paramedical a {
      background-color: #f16804;
      color: #ffffff;
    }
  }
  .field-name-field-societe .field-items {
    padding-top: 10px 0;
  }
}
.node-evenement {
  .field-name-field-societe.field-type-taxonomy-term-reference {
    float: left;
    clear: both;
  }
  .field-name-field-tags.field-type-taxonomy-term-reference {
    float: left;
    .field-items {
      padding: 0;
      clear: both;
    }
  }
}
.node-type-evenement .field-name-field-tags.field-type-taxonomy-term-reference .field-items .field-item {
  display: inline-block;
  margin-right: 5px;
  margin-top: 15px;
  padding: 0;
}
