/*Filtres recherches*/
#views-exposed-form-vue-biblio-page-biblio {
  .form-control {
    height: auto;
    padding: 0;
    border: none;
  }
}
#block-system-main article {
  ul li .chosen-container-multi {
    .chosen-choices li.search-field,
    .chosen-results {
      background: none;
    }
  }
  .chosen-container-single .chosen-results li {
    &.active-result {
      background: none;
      color: #444;
      &:hover {
        background: #96132b;
        color: #ffffff;
      }
    }
    &.result-selected {
      background: none;
    }
  }
  .chosen-container-multi {
    .chosen-choices {
      height: 20px;
      padding: 2px 12px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }
    .chosen-results li {
      &.active-result {
        background: none;
        color: #444;
        &:hover {
          background: #96132b;
          color: #ffffff;
        }
      }
      &.result-selected {
        background: none;
      }
    }
  }
  .chosen-container-active {
    .chosen-choices,
    .chosen-single {
      border: 1px solid #a32e3d;
    }
  }
}
/*Liste des ressources*/
.view-vue-biblio {
  h3 {
    margin-top: 15px;
    margin-bottom: 15px;
    color: #e4a704;
    font-size: 130%;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    letter-spacing: normal;
  }
  .views-row {
    margin-bottom: 10px;
    border-bottom: 1px dashed #ddd;
  }
  .views-row-last {
    margin-bottom: 30px;
  }
  .views-row .views-field-title a {
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  .tag_liste_biblio {
    ul {
      padding: 0;
      li {
        list-style: none;
        background: none;
        margin-left: 0;
        padding-left: 0;
        margin-bottom: 0;
      }
    }
    .field-type-taxonomy-term-reference {
      &.field-name-field-tags {
        float: none;
      }
      .field-items {
        .field-item {
          background: #e9e9e9;
          display: inline-block;
          padding: 0 11px;
          margin-right: 13px;
          border-radius: 20px;
          a {
            font-size: 11px;
            color: #333;
            text-decoration: none;
            font-weight: bold;
            font-size: 90%;
          }
        }
        margin-top: 8px;
        margin-bottom: 15px;
        border-top: 0;
        padding-top: 0;
      }
    }
  }
  .pager {
    width: 10%;
    margin: auto;
    background: none;
    border: none;
    li.pager-show-more-next a {
      color: #ffffff;
      background: #96132b;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      padding: 8px 15px;
      font-size: 1.2em;
    }
  }
}
.view-liste-referentiels .pager li.pager-show-more-next a {
  color: #ffffff;
  background: #96132b;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 1.2em;
}
.view-liste-referentiels .pager li.pager-show-more-next a:hover,
.view-vue-biblio .pager li.pager-show-more-next a:hover {
  color: #1a1a1a;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 1.2em;
}
/*Une ressource de la biblio*/
.node-type-ressource {
  h2 {
    margin-top: 15px;
  }
  .field-type-taxonomy-term-reference {
    .field-items {
      padding-top: 10px 0;
      clear: both;
      .field-item {
        background: #e9e9e9;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 20px;
        margin-right: 15px;
      }
    }
    .field-item a {
      color: #333333;
      text-decoration: none;
      font-weight: bold;
      font-size: 90%;
      &:hover {
        text-decoration: none;
        text-decoration: none;
        color: #555;
      }
    }
  }
  .content {
    .field-name-field-auteur {
      .field-label {
        font-weight: normal;
      }
      .field-items .field-item {
        font-weight: bold;
      }
    }
    .field-name-field-date-parution {
      .field-label {
        font-weight: normal;
      }
      .date-display-single {
        font-weight: bold;
      }
    }
    .field-name-field-documents {
      img {
        margin-right: 3px;
      }
      a {
        font-weight: bold;
        text-decoration: none;
        line-height: 16px;
        vertical-align: middle;
      }
    }
  }
}
