// Surchage Bootstrap
.label {
  color: #444;
}
// Cacher icone Imprimer
#printer-friendly {
  display: none;
}
// Correstion bug Chosen et block-footer-sitemap-footer-sitemap
.webform-component-select {
  .form-control {
    padding: 0;
    border: none;
    height: auto;
  }
}

//Merci de votre adhésion à la SFD Médical

#thankyou_text h2 {
  display: none
}


// Formulaire parainage
.view-espace-perso-mes-parrainages {
  textarea {
    min-height: 40px;
    width: 100%;
  }
  button.webform-submit {
    float: right;
    &:hover {
      color: $white;
    }
  }
}
// /*Formulaire d'adhesion Version 1*/
// .webform-client-form-308 .webform-container-inline label {
//   display: inline-block;
//   margin-right: 1em;
//   width: 14%;
//   text-align: right;
// }
// .mbs-inscription-form {
//   .webform-component--Identite--fieldset-wrapper--info-email p {
//     margin-bottom: 0;
//   }
//   p span.infoMail {
//     color: #a32e3d;
//     display: block;
//     font-weight: bold;
//     margin-left: 28%;
//     margin-top: 3%;
//     text-align: left;
//   }
//   .webform-client-form button.webform-submit {
//     margin: inherit;
//     float: right;
//   }
// }
/* CiviCRM */
/*retrait message warning sur formulaire civi paiement*/
/* -> Le champ de recherche debug est inconnu. Veuillez vérifier et corriger vos groupes dynamiques associés aux contributions.*/
.page-civicrm-contribute-transact .block-system-main div.messages {
  display: none !important;
}
a.submit-button {
  background: #96132b;
  color: #fff !important;
  border: none;
  text-shadow: inherit;
  border-radius: inherit;
  padding: 8px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-size: 1rem;
}
.webform-progressbar {
  width: 61%;
  text-align: center;
  margin: 0;
}
.webform-progressbar-inner {
  height: 6px;
}
.webform-progressbar-page {
  &.completed {
    background-color: #59a02e;
  }
  &.current {
    background-color: #e4a704;
  }
  width: 30px;
  height: 30px;
  margin: -27px -4px;
  border-radius: 40px;
  border: 7px solid #fff;
  background-color: #eee;
  .webform-progressbar-page-number {
    display: none;
  }
  .webform-progressbar-page-label {
    top: 20px;
    position: relative;
    top: 10px;
    margin: 0 -10em;
  }
}
//Formulaire adhésion
/*Barre de progression*/
.webform-progressbar-outer {
  margin: 2em -1px 4em;
  background-color: #eee;
  border: none;
}
.webform-progressbar-page .webform-progressbar-page-label {
  top: 30px;
  font-size: 1.2em;
}
.mbs-inscription-form {
  .node-webform {
    .webform-client-form {
      max-width: 738px;
      .form-actions {
        .webform-next {
          text-align: right;
        }
        margin-bottom: 20px;
      }
      .form-textarea {
        height: 100px !important;
      }
      .fieldset_wrapper_civi {
        background: #eee;
        border-radius: 20px;
        padding: 15px;
        // Etape 1
        .password-suggestions {
          ul {
            border: 1px solid #ddd;
            margin-top: 4%;
            font-size: .9em;
          }
        }
        // Etape 2
        // Champs téléphone
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box4-civicrm-1-contact-4-phone-phone-ext,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box5-civicrm-1-contact-5-phone-phone-ext,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box-civicrm-1-contact-1-phone-phone-ext,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box2-civicrm-1-contact-2-phone-phone-ext,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box3-civicrm-1-contact-3-phone-phone-ext {
          display: inline-block;
        }
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box4-civicrm-1-contact-4-phone-phone,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box5-civicrm-1-contact-5-phone-phone,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box-civicrm-1-contact-1-phone-phone,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box2-civicrm-1-contact-2-phone-phone,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box3-civicrm-1-contact-3-phone-phone {
          // width: 185px;
          display: inline-block;
        }
        // Adresse de correspondance
        .webform-component--adresse-professionnelle--fieldset-wrapper--fieldset-wrapper {
          border: 1px dashed #000;
          margin-top: 30px;
          padding: 20px;
          @media only screen and (min-width: 768px) {
           .help-block {
            display: block;
            max-width: 100%;
            margin-left: 0;
            margin-top: 4px;
            }
          }
          .webform-component--adresse-professionnelle--fieldset-wrapper--fieldset-wrapper--adresse-de-facturation {
            margin-left: 0;
            margin-top: 30px;
          }
        }
        // Adresse personnelle
          .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-civicrm-1-contact-2-address-country-id,
          .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-civicrm-1-contact-2-address-state-province-id {
            .control-label {
              margin-right: 1em;
            }
            .form-select {
              display: inline-block;
              border: 1px solid #ccc;
              height: 30px;
              padding: 5px 12px;
              max-width: 250px;
            }
          }

        // Etape 4
        #edit-submitted-vie-professionnelle-fieldset-wrapper-civicrm-1-contact-1-cg1-custom-19-ajax-wrapper {
          @media only screen and (min-width: 768px) {
          // float: right;
          margin-right: 3em;
          }
        }

        // Formation professionnelle et continue
        .panel {
           margin-bottom: 20px;
           background-color: transparent;
        }
        .panel-default > .panel-heading {
          background-color: transparent;
          border: 0;
        }
        .form-textarea-wrapper {
          display: inline-block;
          max-width: 400px;
        }

        // Etape 5
        // Formation professionnelle et continue
        .webform-component--vie-professionnelle--fieldset-wrapper--formation-professionnelle-et-continue-fieldset {
          border: 0;
          .form-control {
            height: 100px !important;
          }

        }
        // Etape 5
        .webform-component--parrainage--parrainage {
          .webform-client-form {
            input {
              width: 185px;
            }
            .token-input-list {
              li {
                background: none !important;
              }
            }
          }
        }
        .token-input-list {
          margin : 0 !important;
          height: 33px !important;
          .token-input-delete-token {
            line-height: 33px;
            margin-top: -10px;
          }
        }
        .webform-component--parrainage--fieldset-wrapper--civicrm-1-contact-1-contact-existing,
        .webform-component--parrainage--fieldset-wrapper--civicrm-2-contact-1-contact-existing,
        .webform-component--parrainage--fieldset-wrapper--civicrm-3-contact-1-contact-existing  {
          line-height:55px;
          .control-label {
          line-height:33px
          }
        }
      .form-actions,
      .form-item {
        margin-top: 15px;
        margin-bottom: 0;
        }
      }
    }
  }
}
/* Formulaires acceptation/refus parrainage */
.view-espace-perso-mes-parrainages {
  .views-field-name-1 {
    text-align: center;
  }
  .views-field-name-2 {
    text-align: center;
  }
  &.form-accept-parrainage {
    .fake-link {
      float: right;
      font-size: 1.2em;
      font-weight: bold;
      margin-top: .5%;
      padding: 11px;
      background: #96132b;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
    }
    button#accept-btn {
      float: right;
      border: 0;
      font-size: 1em;
      margin-top: .5%;
      background: #96132b;
      border-radius: 10px;
      .fake-link {
        text-shadow: none;
      }
    }
    h3 {
      display: inline-block;
    }
  }
  &.form-refus-parrainage {
    .fake-link {
      float: right;
      font-size: 1.2em;
      font-weight: bold;
      margin-top: .5%;
      padding: 11px;
      background: #96132b;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
    }
    button#deny-btn {
      float: right;
      border: 0;
      font-size: 1em;
      margin-top: .5%;
      background: #96132b;
      .fake-link {
        text-shadow: none;
      }
    }
    h3 {
      display: inline-block;
    }
  }
}
.webform-confirmation {
  .btn:hover {
    font-weight: normal;
  }
}
/* Fin formulaires acceptation/refus parrainage */
/******* acces adhésion ***********/
.sfdAccessForm {
  .infoEtMod {
    text-align: center;
  }
  .leftaccess,
  .rightaccess {
    margin-top: 20px;
    cursor: pointer;
    border-radius: 10px;
    padding: 2%;
    margin-bottom: 30px;
    box-shadow: 0 10px 33px 10px #cccccc;
    ul {
      font-size: 13px;
      li {}
    }
    img {
      max-height: 36px;
      border: 0;
    }
    input {
      border: none;
      width: 100%;
      border-radius: 15px;
      height: 34px;
      line-height: 34px;
      padding: 0 0 0 10px;
      font-size: 24px;
      bottom: 7%;
      left: 0;
      right: 0;
      &.validate {
        height: 75px;
        color: #ffffff;
        cursor: pointer;
        font-weight: normal;
        width: 50%;
        display: block;
        margin: auto;
        padding: 0;
        &.active {
          box-shadow: 0 0 13px 4px #fff;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          border-radius: 15px;
          font-size: 14px;
        }
      }
    }
  }
  .leftaccess {
    cursor: pointer;
    border-radius: 10px;
    padding: 2%;
    background: -moz-linear-gradient(top, #1badc7 0%, #1badc7 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1badc7), color-stop(100%, #1badc7));
    background: -webkit-linear-gradient(top, #1badc7 0%, #1badc7 100%);
    background: -o-linear-gradient(top, #1badc7 0%, #1badc7 100%);
    background: -ms-linear-gradient(top, #1badc7 0%, #1badc7 100%);
    background: linear-gradient(to bottom, #1badc7 0%, #1badc7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#1badc7', endColorstr='#1badc7', GradientType=0 );
    p,
    ul {
      color: #b6f4ff;
    }
    input.validate {
      background-color: #1794aa;
    }
  }
  .rightaccess {
    background: -moz-linear-gradient(top, #f16804 0%, #f16804 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f16804), color-stop(100%, #f16804));
    background: -webkit-linear-gradient(top, #f16804 0%, #f16804 100%);
    background: -o-linear-gradient(top, #f16804 0%, #f16804 100%);
    background: -ms-linear-gradient(top, #f16804 0%, #f16804 100%);
    background: linear-gradient(to bottom, #f16804 0%, #f16804 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#f16804', endColorstr='#f16804', GradientType=0 );
    p,
    ul {
      color: #ffdfc8;
    }
    input.validate {
      background-color: #df6003;
    }
  }
  // float: left;
  // min-height: 714px;
  // padding-left: 5%;
  // padding-right: 5%;
  // margin-left: auto;
  // margin-right: auto;
  // margin-bottom: 5%;
  h1 {
    margin-bottom: 6%;
    color: #ffffff;
    font-family: "Exo 2", sans-serif;
    font-weight: 100;
    font-size: 27px;
    text-align: center;
  }
  p {
    width: 100%;
    line-height: 15px;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
    line-height: 1.7em;
    font-size: 14px;
  }
  .rightaccess p {
    color: #ffdfc8;
  }
  a {
    text-decoration: underline;
    color: #67879a;
  }
  p.adherer {
    background-color: #90d430;
    color: #ffffff;
    text-align: center;
    margin-bottom: 5%;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    a {
      text-decoration: none;
      padding: 2%;
      display: block;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
    }
  }
  .freeMedias {
    a {
      text-decoration: none;
      padding: 2%;
      display: block;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
    }
    position: absolute;
    left: 0;
    bottom: 0;
    p {
      margin: 0;
      text-align: center;
      img {
        width: 100%;
        height: auto;
      }
    }
    a.lienMedias {
      background-color: #20a3ce;
      color: #ffffff;
      text-align: center;
      position: absolute;
      bottom: 9%;
      right: 1%;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
    }
  }
  p.adherer a {
    color: #ffffff;
  }
  .forgetPassword a {
    color: #67879a;
  }
  .leftaccess input.validate.active,
  .rightaccess input.validate.active {
    box-shadow: 0 0 13px 4px #fff;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    padding: 0;
    font-size: 2.7rem;
  }
}
/*.sfdAccessForm h1 .h1Big { font-weight: 400; font-size: 2rem; }*/
/*Etat adhesion dans recapitulatif perso*/
.logged-in.page-user .profile {
  .crm-profile-name-Adresse_Personnelle_14,
  .crm-profile-name-Profil_annuaire_15,
  .crm-profile-name-name_and_address,
  > h3,
  dl {
    display: none;
  }
}
.profile .view-etat-adhesion {
  padding: 10px 20px 25px;
  border: 2px solid #96132b;
  margin-bottom: 65px;
  .view-header h3 {
    display: inline;
    color: #595959;
  }
  .submit-button {
    font-size: 14px;
  }
  .view-content {}
  .views-num-ro-de-membre-14 {
    background: #595959;
    color: #ffffff;
    border-radius: 3px;
    padding: 5px 6px;
    display: block;
    margin-top: 10px;
    position: relative;
    width: 220px;
    text-align: center;
    .field-content {
      font-weight: bold;
    }
  }
  .view-content a.btn-renouveler {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.55) 100%);
    border-radius: 15px;
    color: #ffffff;
    padding: 7px;
    margin-left: 10px;
  }
}
.view-lien-em-espace-perso {
  padding: 10px 20px 25px;
  border: 2px solid #96132b;
  margin-bottom: 65px;
  min-height: 164px;
  .view-header h3 {
    color: #595959;
    margin-top: 0;
  }
  .views-field.views-field-field-profile-picture {
    float: left;
  }
  &.view-display-id-entity_view_1 .views-field.views-field-php {
    margin-top: 20px;
    margin-left: 10px;
  }
  div.em-head {
    margin-bottom: 20px;
  }
}
.view-display-id-view_eva_historique .field-content div {
  display: inline;
}
.view-lien-em-espace-perso {
  span.em-link-label {
    width: 90%;
    display: inline-block;
    text-align: justify;
  }
  div.em-head a.button-link {
    width: 180px;
    display: block;
    padding: 0 8px;
    text-align: center;
  }
}
/*Paiement de la cotisation */
#membership legend,
fieldset.crm-group legend {
  font-weight: bold;
  color: #a32e3d;
  padding: 0 5px;
  font-size: 1.2em;
}
#pricelabel label {
  padding-right: 21px;
}
.payment_options-group .payment_processor-section .content input {
  float: left;
  clear: both;
  margin-top: 13px;
}
#crm-container .payment_processor-section .label {
  margin-top: 10px;
}
.payment_processor-section {
  > .label {
      position:absolute;
  }
  .content {
    label {
      float: left;
      margin-top: 10px;
    }
  }
}
.crm-container {
  span {
    &.crm-button-type-next,
    &.crm-button-type-upload {
      float: right !important;
    }
  }
  .header-dark {
    background: none;
    border-bottom: 2px solid #a32e3d;
    color: #a32e3d;
  }
  .crm-button.crm-icon-button {
    background: #a32e3d;
    border: 1px solid #a32e3d;
    input {
      background-color: #a32e3d;
      text-shadow: none;
      font-weight: bold;
      font-size: 14px;
      padding: 1px 5px 2px 18px;
    }
  }
  #membership #help {
    color: #ffffff;
    background: #96132b;
    border: 1px solid #96132b;
    margin: 15px auto;
    width: 50%;
    font-size: .9em;
  }
  .CRM_Contribute_Form_Contribution_Confirm {
    #help {
      color: #ffffff;
      background: #96132b;
      border: none;
      margin: 15px auto;
      width: 50%;
      font-size: .9em;
      padding: 10px 0;
      p {
        text-align: center;
        margin-bottom: 0;
      }
    }
    .continue_instructions-section {
      color: #ffffff;
      background: #96132b;
      margin: 15px auto;
      width: 50%;
      border: none;
      font-size: .9em;
      padding: 10px 0;
      p {
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
  .CRM_Contribute_Form_Contribution_ThankYou #help {
    background: none;
  }
  .crm-contribution-thankyou-form-block .amount_display-group {
    display : none;
  }
}
/*webform*/
.forceDisplay {
  display: inline-block !important;
}
/*Admin*/
.contextual-links-wrapper ul li {
  list-style: none;
}
/* Contribution page */
.crm-contribution-main-form-block {
  .crm-form-radio {
    margin-right: 5px;
  }
}
.crm-not-you-message {
  display: none;
}
// Correction bug affichage module image field_crop
.imagefield-crop-preview {
  .img-responsive {
    max-width: inherit;
  }
}

// Suppresion bordure image paiement par CB
.page-civicrm-sipscreditcards {
  img {
    border:0 !important;
  }
}
