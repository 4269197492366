.page-node-1096 {
  .abstract {
    margin-top: 20px;
    background: #f4f4f4;
    padding: 20px 40px;
    .identifer, .about, .keyword {
      background: #fff;
      border-radius: 15px;
      font-weight: bold;
      padding: 5px 10px;
    }
    .identifer {
      color: #96132B;
    }
    .keywords {
      margin-top: 10px;
    }
    h1, h2 {
    font-weight: bold;
    }
    h1 {
      color: #96132B;
    }
    h2 {
      color: #000;
    }
    .authors, .organizations {
      margin-left: -40px;
    }
    .author {
      display: inline-block;
      text-decoration: underline;
      &:nth-child(1) {
        font-weight: bold;
      }
    }
    .organization {
      margin-left: 15px;
      .location, .address {
        display: inline-block;
      }
    }
  .pdf {
    margin-top: 30px;
    margin-left: 30px;
    &::before {
      position: absolute;
      margin-left: -30px;
      font-family: 'FontAwesome';
      content: '\f1c1';
      font-size: 30px;
      }
    }
  }
}

#block-block-3,
#block-block-4 {
  ul {
    li {
      a {
      img {
        max-height: 30px;
      }
    }
  }
  li:nth-child(3) {
    background: #333;
    i {
      display:block;
      color: #999;
      margin-top: 3px;
      &::before {
        font-size: 27px;
        @media only screen and (min-width: 1200) {
          font-size: 30px;
        }
      }
    }
    &:hover {
     i {
        color: #fff;
        }
      }
    }
  }
}
