@media only screen and (min-width: 1200px) {

  .main-container {
    padding-bottom: 30px;
  }

  .container-fluid {
    max-width: 1560px;
  }

  // Marges Contenu
  .contenu {
    padding-right: 15px;
  }

  .sidebar {
    padding-right: 2vw;
  }
  .navbar-header {
    left: -15px;
    .logo {
      img {
        max-width: 200px;
        padding: 20px 40px;
      }
    }
  }
  .block-cyim-sfd-header-alert-block {
    position: absolute;
    top: 5px;
    left: 43vw;
    .alert {
      padding: 7px 10px;
    }
  }
  #search-block-form {
    position: relative;
    margin-top: -131px !important;
    // margin-right: -15px !important;
    max-width: 285px;
    float: right;
  }

  // Menu
  .tb-megamenu {
    .nav-collapse {
      .dropdown-menu {
        height: 374px;
      }
    }
  }

  #block-tb-megamenu-main-menu {
    margin-top: -42px;
  }
  .slider-accueil {
    max-width: 1545px;
  }

.footer-home, .footer {
    .container-fluid {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  #block-system-user-menu {
    width: 50%;
  }
  // Menu
  .tb-megamenu {
    margin-top: 30px;
    .nav {
      > li {
        margin-right: 8px;
        > a {
          padding: 20px 12px;
        }
      }
    }
    .nav-collapse {
      margin-left: 258px;
    }
    .menu-presse.level-1 {
      a {
        background: #a88e5c !important;
        border-radius: 10px 10px 0 0;
        color: white;
        &:hover {
          background: $red !important;
          border-radius: 10px 10px 0 0;
          color: white;
        }
      }
    }
  }
  .breadcrumb {
    margin-top: -35px;
    padding-bottom: 9px;
  }
  .home-medical,
  .home-paramedical {
    .breadcrumb {
      height: 56px;
      margin-top: -25px;
      margin-bottom: 0;
      &::before {
        margin-top: -55px;
        height: 97px;
        margin-left: 40px;
        width: 119px;
      }
    }
    h1 {
      &.page-header {
        top: -60px;
        left: 10vw;
        color: $white;
        font-size: 40px;
        font-weight: bold;
        border: none;
        z-index: 100;
      }
    }
    #block-views-agenda-home-commun-block-1,
    #block-views-agenda-home-commun-block-2 {
      margin-top: 55px;
    }
  }



  // Page adhésion
  .sfdAccessForm {
    p {
      width: 100%;
    }
    .leftaccess,
    .rightaccess {
      height: 670px;

      .infoEtMod,
       input.validate {
         position: absolute;
      }
      input.validate {
        bottom: 70px;
      }
      .infoEtMod {
        bottom: 20px;
        text-align: center;
        left: -20px;
      }
    }
  }
  // Accès Directs
  #block-block-3,
  #block-block-4 {
    ul {
      li {
        border-right: none;
        padding: 8% 0 63.3%;
        display: block;
        width: 100%;
        margin: 0;
        float: left;
        height: 0;
      }
    }
  }
  #block-block-3 {
    ul {
      li {
        padding: 8% 0 48.85%;
        a {
          font-size: 12px;
        }
        &:nth-child(5) {
          border-bottom: none
        }
      }
    }
  }
  #block-block-4 {
    margin-top: 30px;
    margin-bottom: 25px;
    ul {
      li {padding: 15px 0;
        height: auto;
        a {
          font-size: 12px;
          img {
            max-height: 30px;
            margin-bottom: 7px;
          }
        }
      }
    }
  }


  #block-block-4 {
    ul {
      li {
        a {
          font-size: 11px;
        }
      }
    }
  }


  .footer {
    #block-block-6 {
      a,
      p {
        &.ffrd-link {
          width: 100px;
          height: 52px;
        }
        &.ffd-link {
          width: 100px;
          height: 52px;
        }
        &.sfe-link {
          width: 100px;
          height: 46px;
        }
      }
    }
  }



  .slider-accueil {
    margin-left: 0;
    margin-right: 0;
  }
  #block-views-agenda-home-commun-block,
  #block-views-agenda-home-commun-block-1,
  #block-views-agenda-home-commun-block-2 {
    ul {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .home-medical,
  .home-paramedical {
    .breadcrumb {
      position: relative;
      max-width: 1530px;
      &::before {}
    }
    h1 {
      &.page-header {
        top: -92px;
      }
    }
    .view-actualites-home-commun {
      &.view-display-id-block_1,
      &.view-display-id-block_2 {
        top: -100px;
      }
    }
    #block-views-agenda-home-commun-block-1,
    #block-views-agenda-home-commun-block-2 {
      margin-top: 32px;
    }
  }
  /*Menu Outils et raccourcis*/
  .mediatheque-videos {
    .navbar-header {
    left: 0;
}
    #block-block-5 ul li {
      width: auto;
    }
  }

  #block-block-5 {
    left: -80px;
    top: 270px;
    right: auto;
    bottom: auto;
    text-align: right;
    background: transparent;
    transition: .5s;
    &:hover {
      transform: translateX(80px);
      margin: 0;
    }
    ul {
      margin: 0;
      li {
        display: block;
        max-width: 150px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-bottom: 1px;
        height: 61px;
        width: auto;
        border: 1px solid #ccc;
        text-align: right;
        border-left: 0;
        line-height: 3;
        a {
          display: inline-block;
          color: $red;
          height: 61px;
          padding: 0 10px;
          font-size: 12px;
          &:hover {
            opacity: .5;
            text-decoration: none;
          }
          span {
            text-align: right;
            font-size: 26px;
            display: inline-block;
            line-height: 1.7;
            padding: 5px 0 5px 20px;
            &.fa-facebook {
              padding-right: 5px;
            }
            &.fa-handshake-o {
              margin-right: -5px;
            }
          }
        }
      }
    }
  }

}
