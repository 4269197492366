// Alerte notification
.block-cyim-sfd-header-alert-block {
  .alert {
    z-index: 3;
    margin-bottom: 0;
    padding: 10px;
    background-color: $brand-warning;
    a {
      color: $white;
    }
  }
}
// Recherche
.block-search {
  max-height: 37px;
  margin-left: -15px;
  margin-right: -15px;
}
#search-block-form {
  display: table;
  width: 100%;
  margin-left: 0;
  .form-text {
    display: table-cell;
    background: $red;
    border: none;
    color: $white;
    border-radius: 0;
    height: 37px;
    &::-webkit-input-placeholder {
      color: $white;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $white;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $white;
    }
    &:-ms-input-placeholder {
      color: $white;
    }
  }
  .input-group-btn {
    display: table-cell;
    .btn {
      height: 37px;
      width: 37px;
      border-radius: 0;
      border: none;
      border-color: $red;
      padding: 0;
      background: $red;
      font-size: .1px;
      color: $red;
      &::after {
        font-family: $font-icon;
        color: $white;
        content: "\f002";
        font-size: 18px;
      }
    }
  }
  .form-group {
    margin-bottom: 1px;
  }
}
.navbar-default {
  border: 0;
  background: $white;
  margin-bottom: 0;
}
.container-fluid {
  > .navbar-header {
    float: left;
    z-index: 2;
    position: relative;
    // margin-left: 2px;
    .logo {
      float: left;
      margin: 0;
    }
  }
}
// Menu User
#block-system-user-menu {
  margin-top: 4px;
  .nav {
    & > li {
      display: inline-block;
      font-size: 12px;
      font-weight: bold;
      > a {
        &:hover {
          text-decoration: underline;
        }
      }
      &.first,
      &.first.open {
        background: $red;
        > a {
          &:hover {
            color: $white;
            text-decoration: underline;
            background: $red;
          }
        }
      }
      &.open {
        background: $white;
         > a:hover,
        > a:focus {
          background: $white;
        }
      }
    }
  }
}
.not-logged-in {
  #block-system-user-menu {
    .nav {
      & > li {
        > a {
          color: $white;
        }
        &.last {
          background: $red-light;
          > a {
            background: $red-light;
          }
          &:hover {}
        }
      }
    }
  }
}
.logged-in {
  #block-system-user-menu {
    .nav {
      & > li {
        a {
          color: $white;
        }
        &:nth-child(2) {
          display: none;
        }
        &.last,
        &.open {
          background: $white;
          a {
            color: #777;
             > a:hover,
            > a:focus {
              background: $white;
            }
          }
        }
      }
    }
  }
}
// BANDEAU
.bandeauphoto {
  background: url("../img/bandeau_generique.jpg");
  width: 100%;
  margin: auto;
  background-size: cover;
  height: 0;
  padding-bottom: 30%;
}
.medical .bandeauphoto,
.sub-medical .bandeauphoto {
  background: url("../img/bandeau_medical.jpg");
  background-size: cover;
}
.paramedical .bandeauphoto,
.sub-paramedical .bandeauphoto {
  background: url("../img/bandeau_paramedical.jpg");
  background-size: cover;
}
.recommandations .bandeauphoto {
  background: url("../img/bandeau_recommandations.jpg");
  background-size: cover;
}
.groupes-de-travail .bandeauphoto {
  background: url("../img/bandeau_groupes_de_travail.jpg");
  background-size: cover;
}
.mediatheque .bandeauphoto, .node-type-article-qdm .bandeauphoto{
  background: url("../img/bandeau_mediatheque.jpg");
  background-size: cover;
}
.presse .bandeauphoto {
  background: url("../img/bandeau_presse.jpg");
  background-size: cover;
}
// Fil d'Ariane
.breadcrumb {
  margin-bottom: 0;
  padding: 5px 0 0 10px;
  color: #ffffff;
  background-color: transparent;
  a {
    &:link,
    &:visited {
      color: #ffffff;
      font-size: 90%;
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  span.delimiter {
    color: #ffffff;
    font-size: 90%;
    text-decoration: none;
  }
}
