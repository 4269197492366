@media only screen and (min-width: 1366px) {
  .breadcrumb {
    margin-top: -40px;
    padding-bottom: 14px;
  }
  .home-medical,
  .home-paramedical {
    h1 {
      &.page-header {
        left: 8vw;
      }
    }
  }
}
@media only screen and (min-width: 1600px) {
  // Marges Contenu
  .contenu {
    padding-left: 3vw;
  }
  aside {
    &.col-md-2,
    &.col-sm-4 {
      padding-right: 3vw;
    }
  }
}
@media only screen and (min-width: 1900px) {
  // .row {
  //   &.slider-accueil {
  //     margin-left: 0;
  //     margin-right: 0;
  //   }
  // }
  .breadcrumb {
    margin-top: -43px;
    padding-bottom: 17px;
  }
  #block-block-4 {
    margin: 50px -26px 25px;
  }
}
