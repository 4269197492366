@media only screen and (max-width: 479px) {
  // Titre adhésion + Page formulaire adhésubmit-button
  .page-node-692,
  .page-node-696 {
    h1.page-header {
      background: url("../img/titleh1-fleche-gris-fonce.png") no-repeat 40px bottom;
    }
  }
  // Menu user connecté
  .logged-in {
    #block-system-user-menu .nav > li > a {
      padding: 10px 3px;
    }
  }
  // Fil d'Ariane
  .breadcrumb {
    color: #595959;
    a {
      &:link,
      &:visited {
        color: #595959;
      }
    }
    span.delimiter {
      color: #595959;
    }
  }
  // Page Log-in
  .page-user-login {
    .nav-tabs > li > a {
      max-width: 170px;
      font-size: 12px;
    }
  }
  // Acces direct
  #block-block-3,
  #block-block-4 {
    ul {
      li {
        a {
          font-size: 0;
        }
      }
    }
  }
  // Footer
  .footer {
    #block-block-6 {
      a,
      p {
        &.ffrd-link {
          width: 86px;
          height: 46px;
        }
        &.ffd-link {
          width: 86px;
          height: 46px;
        }
        &.sfe-link {
          width: 86px;
          height: 46px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .views-field-field-image-actualite {
    display: none;
  }
  .flex-control-nav {
    display: none;
  }
  .addthis_button {
    margin-right: 15px;
  }
  .view-annuaire-membres-sfd {
    ul.cardlist li.card .infoBase {
      .mail,
      .profession,
      .second-part,
      .tel {
        clear: both;
        margin: 0 0 0 5px;
      }
      .second-part {
        clear: both;
        margin-bottom: 5px;
      }
    }
  }
  .webform-progressbar {
    display: none;
  }
  // Formulaire adhésion
  // Etape 1
  .mbs-inscription-form {
    .node-webform {
      .webform-client-form {
        max-width: 290px;
        .fieldset_wrapper_civi {}
      }
    }
    // Champs date anniversaire
    .form-item-submitted-identite-fieldset-wrapper-civicrm-1-contact-1-contact-birth-date {
      label {
        display: block;
      }
      .form-item {
        display: inline-block;
      }
    }
    // Champs mot de passe
    .password-parent {
      .password-strength,
      .password-confirm {
        float: none;
        margin-bottom: 10px;
        display: block;

      }
    }
  }
  // Etape 2
  // Champs téléphone
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box4-civicrm-1-contact-4-phone-phone-ext,
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box5-civicrm-1-contact-5-phone-phone-ext,
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box-civicrm-1-contact-1-phone-phone-ext,
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box2-civicrm-1-contact-2-phone-phone-ext,
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box3-civicrm-1-contact-3-phone-phone-ext {
    display: inline-block;
  }
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box4-civicrm-1-contact-4-phone-phone,
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box5-civicrm-1-contact-5-phone-phone,
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box-civicrm-1-contact-1-phone-phone,
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box2-civicrm-1-contact-2-phone-phone,
  .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box3-civicrm-1-contact-3-phone-phone {
    width: 169px;
    display: inline-block;
  }
  // Etape 4 :
  .form-item-submitted-vie-professionnelle-fieldset-wrapper-civicrm-1-contact-1-cg2-custom-6 {
    label {
      display: block;
    }
    .form-radios {
      .form-item {

        display: block;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  #block-block-6 {
    > div {
      margin-bottom: 10px;
    }
  }
  .region-mentions-legales {
    margin-bottom: 45px;
  }
  .mediatheque .contenu.col-xs-12,
  .mediatheque-videos .contenu.col-xs-12 {
    padding-right: 0;
    padding-left: 0;
    margin-left: 15px !important;
    margin-right: -15px !important;
    .bgPageBox {
      margin: 0 -15px;
    }
    .contentBox {
      margin: 0 15px;
    }
  }
}
@media only screen and (max-width: 979px) {
  .tb-megamenu .nav-collapse .nav > li > a:focus,
  .tb-megamenu .nav-collapse .nav > li > a:hover {
    background-color: $red;
  }
  .tb-megamenu .nav-collapse .nav > li.open > a {
    color: $white !important;
  }
}
