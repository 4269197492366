@import 'variables';

@media only screen and (max-width: 979px) {
  .navbar {
    .container-fluid {
      padding: 0;
      margin: 0;
    }
  }
  .navbar-header {
    .logo {
      padding: 5px;
      img {
        max-width: 50px;
      }
    }
  }
  #block-system-user-menu {
    .nav {
      & > li {
        right: -15px;
        & > a {
          padding: 10px 9px;
        }
      }
    }
  }
  .tb-megamenu-button {
    right: 0;
    position: absolute;
    top:-45px;
        line-height: 16px !important;
    float:none;
    text-transform: uppercase;
    color: $gray !important;
    font-size: 1.2rem !important;
    max-width: 110px;
    text-shadow: none !important;
    background: $white !important;
    box-shadow: none;
    border: 1 solid $gray !important;
    border-radius: 0 !important;
    padding: 9px !important;
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }
  .tb-megamenu {
    background: #fff;
    margin-top: 0;
    .dropdown-menu {
      border: inherit;
    }
  }
  ul {
    &.tb-megamenu-nav li {
      height: inherit;
      border-right: none;
      &.niv1-publications {
        border: none;
      }
    }
    &.tb-megamenu-subnav li {
      height: inherit;
      background: inherit;
      border: inherit;
    }
    &.tb-megamenu-nav li a {
      font-family: "Raleway", sans-serif;
      font-weight: 500;
      line-height: inherit;
      height: inherit;
      border: none !important;
    }
    &.tb-megamenu-subnav li a {
      text-transform: inherit;
      height: inherit;
      line-height: inherit;
    }
  }
  .tb-megamenu {
    a {
      &:link,
      &:visited {
        text-decoration: none;
      }
    }
    .nav {
      > {
        li > a {
          font-weight: normal;
          color: #595959;
          border: inherit;
          padding: 0 12px;
        }
        .active > a {
          background: none;
          &:focus {
            background: none;
          }
        }
      }
      li.dropdown {
        &.active > .dropdown-toggle,
        &.open.active > .dropdown-toggle {
          background: none;
        }
      }
      > {
        .active > a:hover,
        li.dropdown.open.active > a:hover {
          background: none;
        }
      }
    }
  }
  ul.tb-megamenu-nav li.level-1.open a {
    background: none;
  }
  .nav-collapse .mega-dropdown-menu .tb-megamenu-column-inner .level-1 {
    margin-top: 12px;
  }
  .level-2 a {
    background: inherit !important;
  }
  .tb-megamenu {
    a {
      &:focus,
      &:hover {
        color: #ffffff !important;
        background-color: #850e21;
      }
    }
    .nav li.dropdown.open > .dropdown-toggle {
      color: #850e21;
    }
    .dropdown-menu .active > a {
      color: #850e21;
      &:hover {
        color: #850e21;
      }
    }
    .nav li.dropdown.open > .dropdown-toggle {
      background-color: #850e21;
    }
    .mega-nav .mega-group > .mega-group-title {
      text-transform: inherit;
      font-weight: inherit;
      color: #e9ab04;
      font-family: "Comfortaa", cursive;
      font-size: 120%;
    }
    .dropdown-menu {
      .active .mega-nav .mega-group > .mega-group-title,
      .mega-nav .mega-group > .mega-group-title {
        text-transform: inherit;
        font-weight: inherit;
        color: #e9ab04;
        font-family: "Comfortaa", cursive;
        font-size: 120%;
      }
      li.level-2 > a {
        text-transform: inherit;
        font-weight: inherit;
        color: #e9ab04;
        font-family: "Comfortaa", cursive;
        font-size: 120%;
        &:hover {
          color: #ffffff !important;
        }
      }
    }
  }
  #tb-megamenu-column-6 {
    margin-left: inherit;
  }
  .tb-megamenu {
    .dropdown-menu li.level-3 > a {
      line-height: 15px;
    }
    .nav li.dropdown {
      > .dropdown-toggle .caret {
        border-bottom-color: #b8183a;
        border-top-color: #b8183a;
      }
      &.open {
        &.active .caret,
        .caret {
          border-bottom-color: #b8183a;
          border-top-color: #b8183a;
        }
        a {
          &:focus .caret,
          &:hover .caret {
            border-bottom-color: #b8183a;
            border-top-color: #b8183a;
          }
        }
        > .dropdown-toggle .caret {
          border-bottom-color: #b8183a;
          border-top-color: #b8183a;
        }
      }
      &.active > .dropdown-toggle .caret,
      &.open.active > .dropdown-toggle .caret {
        border-bottom-color: #b8183a;
        border-top-color: #b8183a;
      }
    }
    .nav-collapse {
      background: #850e21;
      margin-top: -27px;
      .dropdown-menu {
        background: #b8183a;
      }
    }
    .dropdown-menu .mega-nav > li a,
    .mega-nav > li a {
      margin-left: 10px;
    }
  }
}
