//Fixe le footer en bas de page
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  display: table;
  width: 100%;
}
footer, .footer-mediatheque {
  display: table-footer-group;
  height: 125px;
  margin-bottom: 0;
  .region-footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
body {
  overflow-x: hidden;
}
//Bug menu
.collapse {
  display: block;
}
// Centrer éléments media
.media-element-container {
  text-align: center;
}
/*Specifique class medical et paramedical caché*/
._medical_cache_,
._medical_cache__paramedical_cache_,
._paramedical_cache_,
._paramedical_cache__medical_cache_ {
  display: none !important;
}
/* select des inputs en degradé */
button:active,
button:focus,
input:active,
input:focus,
textarea:active,
textarea:focus {
  box-shadow: 0 0 7px #fff!important;
}
a.button-link {
  background-color: #a32e3d !important;
  text-shadow: none !important;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 12px;
  margin: 0;
  background: none;
  border: none;
  border-radius: 4px !important;
  color: #FFF !important;
  text-decoration: none;
  cursor: pointer;
  margin: 5px;
  line-height: 50px;
}
// Page logged
#user-login {
  max-width: 600px;
}
// Bloc Sommaire
section[id*="block-menu-block-"] {
  float: none;
  max-width: 768px;
}
// /*Block Add this*/
// .block-addthis {
//   display: block;
//   position: absolute;
//   top: 40px;
//   right: 0;
//   display: block;
//   position: relative;
//   top: 15px;
//   left: 15%;
//   .addthis_button {
//     display: block;
//     width: 25px;
//     height: 25px;
//     background: url("../img/picto-share.png") no-repeat center top;
//     background-size: cover;
//     img {
//       visibility: hidden;
//       display: none;
//     }
//   }
// }
// Post tag articles
.submitted {
  margin-top: 10px;
  font-size: 90%;
}
// Titres
.zone-content {
  background: #fff;
  padding-bottom: 15px;
  padding-top: 15px;
}
.region-content .content {
  padding: 0 10px 10px 0;
}
.page-header {
  border: none;
  margin: 40px 0;
}
h1.page-header {
  border: none;
  margin: 40px 0;
  color: #fff;
  font-weight: 600;
  margin: 15px 0;
  background: url("../img/titleh1-fleche-gris-fonce.png") no-repeat 40px bottom;
  display: inline-block;
  border-radius: 20px;
  padding-bottom: 26px;
  span {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    border-radius: 20px;
    padding: 5px 60px 5px 30px;
    border-radius: 20px;
    line-height: 1.3;
    background-color: #595959;
    display: inline-block;
  }
}
.medical h1.page-header,
.sub-medical h1.page-header {
  background: url("../img/titleh1-fleche.png") no-repeat 40px bottom;
  span {
    background-color: #1badc7;
    // &::before {
    //   border-color: #1badc7 transparent transparent transparent;
    // }
  }
}
.paramedical h1.page-header,
.sub-paramedical h1.page-header {
  background: url("../img/titleh1-fleche-paramedical.png") no-repeat 40px bottom;
  span {
    background-color: #f16804;
    // &::before {
    //   border-color: #f16804 transparent transparent transparent;
    // }
  }
}
.presse h1.page-header {
  background: url("../img/titleh1-fleche-presse.png") no-repeat 40px bottom;
  span {
    background-color: #a88e5c;
    &::before {
      border-color: #a88e5c transparent transparent transparent;
    }
  }
}
.mediatheque h1.page-header,
.ressources h1.page-header,
.sub-mediatheque h1.page-header,
.sub-ressources h1.page-header {
  span {
    background-color: #595959;
    &::before {
      border-color: #595959 transparent transparent transparent;
    }
  }
}
.tb-megamenu .menu-presse.active a {
  background: #a88e5c !important;
  border-radius: 10px;
}
// TAGS
.field-name-field-tags.field-type-taxonomy-term-reference {
  float: left;
  .field-items {
    padding: 0;
    clear: both;
    .field-item {
      background: #e9e9e9;
      display: inline-block;
      margin-right: 15px;
      padding: 5px 10px;
      border-radius: 20px;
      a {
        color: #333;
        text-decoration: none;
      }
    }
  }
}
.region-content .node-page .content .field-type-taxonomy-term-reference .field-item a {
  background-color: #e9e9e9;
  color: #333333;
  text-decoration: none;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 90%;
}
.node-type-actualit-s,
.node-type-article {
  .field-type-text-with-summary {
    margin-bottom: 20px;
    min-height: 190px;
    float: left;
    ul {}
  }
  .field-name-field-societe.field-type-taxonomy-term-reference {
    // float: left;

    clear: both;
    .field-item {
      display: inline-block;
      float: left;
      a {
        background-color: #e9e9e9;
        color: #ffffff;
        text-decoration: none;
        font-weight: bold;
        padding: 5px 10px;
        border-radius: 20px;
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-block;
        float: left;
        font-size: 90%;
      }
      &.Medical a {
        background-color: #1badc7;
      }
      &.Paramedical a {
        background-color: #f16804;
      }
    }
  }
  .field-name-field-tags.field-type-taxonomy-term-reference {
    float: left;
    .field-items {
      padding: 0;
      clear: both;
      .field-item {
        background: #e9e9e9;
        display: inline-block;
        margin-right: 15px;
        padding: 5px 10px;
        border-radius: 20px;
      }
    }
    .field-item a {
      color: #333333;
      text-decoration: none;
      font-weight: bold;
      font-size: 90%;
      &:hover {
        text-decoration: none;
        text-decoration: none;
        color: #555;
      }
    }
  }
}
// Sommaire
.block-menu-block {
  border: 1px solid #ccc;
  float: left;
  z-index: 10;
  margin-top: 20px;
  margin-bottom: 25px;
  h2 {
    margin-top: -10px;
    margin-left: 10px;
    line-height: 25px;
    padding: 0 10px;
    vertical-align: super;
    background-color: #ffffff;
    color: #595959;
    display: inline-block;
    font-size: 120%;
  }
  .menu-name-main-menu .menu li {
    line-height: 1;
    font-size: 90%;
    padding: 5px;
    &::before {
      content: "•";
      color: #f16804;
      font-size: 20px;
      position: relative;
      left: 5px;
      top: 3px;
    }
    &.open a,
    &.open a:hover {
      background: transparent;
    }
    a {
      font-weight: bold;
      text-decoration: none;
      margin-left: -5px;
      display: inline;
      padding: 0 15px;
      &:focus,
      &:hover {
        text-decoration: underline;
        display: inline;
        background-color: transparent;
      }
      &.cadenas {
        padding-right: 20px;
        display: inline-block;
        &::after {
          font-family: FontAwesome;
          content: "\f023";
          color: #595959;
          font-size: 20px;
          position: relative;
          top: 3px;
          left: 10px;
        }
      }
    }
  }
}
.form-actions,
.form-item {
  margin-top: 1em;
  margin-bottom: 1em;
}
// Cas particulier
.cas-particulier {
  border: 1px solid #bbb;
  padding: 20px;
  font-size: 12px;
  line-height: 1;
  p {
    margin-bottom: 0;
  }
}


/**AJOUT DU STYLE DU LECTEUR PDF**/
.field-name-body{
	width:100%;
}

.crm-submit-buttons {
  text-align: center;
  height: 40px !important;
}

.page-civicrm #crm-container input.crm-form-submit.cancel {
  color: #FFF !important;
}

 #crm-container {
   .crm-form-submit {
     color: #FFF !important;
     &.validate {
       margin-top : 0;
     }
   }
   .crm-button-type-upload {
      float:none !important;
      width:100px;
      background: transparent;
      text-align: center;
      margin-left: 18.5%;
      input {
        background-color: #a32e3d !important;
        text-shadow: none !important;
        font-weight: bold;
        font-size: 14px;
        border-radius: 4px !important;
        color: #FFF !important;
        text-decoration: none;
        background-image: none;
        cursor: pointer;
    }
  }
}
.crm-contribution-confirm-form-block {
  > div:nth-child(2) {
    display: none;
  }
  .crm-button {
    background: transparent;
 }
  input {
    background-color: #a32e3d !important;
    text-shadow: none !important;
    font-weight: bold;
    font-size: 14px;
    border-radius: 4px !important;
    color: #FFF !important;
    text-decoration: none;
    background-image: none;
    cursor: pointer;
  }
}

// Cotisation à la SFD
.email-5-section {
  .label {
    line-height: 3;
  }
}

//iframe contenant un PDF
.container-pdf {
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
 .pdf{
	position: absolute;
	border: none;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
}

//personnalisation des pages groupe de travail
.node-groupe-de-travail>div, .node-groupe-de-travail>footer {
    padding:14px 0;
    display:block;
}
.node-groupe-de-travail>div{ 
    border-bottom:1px solid #eee;
}
.node-groupe-de-travail{
  .field{
      .field-label {
        display: block;
        font-size: 18px;
        color: #96132b;
    }
  }
  .field-type-datetime.field-label-inline{
    .field-items{
      .field-item {
        margin-top: 5px;
      }
    }
  }
}