/*Actualites*/
.front .view-id-actualites_home_commun .view-header {
  margin-bottom: .7em;
}
.view-actualites-home-commun .item-list ul li.views-row:nth-last-child::after,
.view-actualites-liste .item-list ul li.views-row:nth-last-child::after {
  height: 0;
}
.view-actualites-home-commun,
.view-actualites-liste {
  .view-header .actualites_title {
    color: #231f20;
    font-weight: 600;
    margin: 0 0 20px;
    background: url("../img/title-fleche-actualites.png") no-repeat 40px bottom;
    display: inline-block;
    border-radius: 20px;
    padding-bottom: 26px;
    font-family: "Raleway",sans-serif;
    font-size: 140%;
    width: 100%;
    span {
      background-color: #e9e9e9;
      padding: 8px 0 8px 30px;
      display: inline-block;
      border-radius: 20px;
      width: 100%;
    }
  }
  .item-list ul {
    margin: 0;
    padding: 0;
    li {
      clear: both;
      margin: 0 0 26px;
      padding-left: 0;
      display: inline-block;
      width: 100%;
      &.views-row {
        &::after {
          content: "";
          position: relative;
          height: 1px;
          background: #ddd;
          width: 200px;
          display: block;
          margin: auto;
          top: 30px;
          margin-bottom: 30px;
        }
      }
      &.views-row-last {
        &::after {
          height: 0;
        }
      }
      .views-field-field-societe ul li {
        border-bottom: none;
      }
      &.views-row-last {
        clear: both;
        margin: 0 0 26px;
        display: inline-block;
        width: 100%;
        border-bottom: none;
      }
      .views-field-field-image-actualite img {
        display: inline-block;
        float: left;
        margin-right: 15px;
        margin-bottom: 25px;
        border: 1px solid #ddd;
      }
      .views-field-field-societe {
        display: inline-block;
        ul {
          padding: 0;
          margin: 8px 0 0;
          li {
            margin: 0;
            .field-item {
              display: inline-block;
              float: left;
              margin-left: 0;
              a {
                font-size: 75%;
                padding: 5px 15px;
                background-color: #e9e9e9;
                color: #ffffff;
                text-decoration: none;
                font-weight: bold;
                border-radius: 20px;
              }
            }
            .Medical a {
              background-color: #1badc7;
            }
            .Paramedical a {
              background-color: #f16804;
            }
          }
        }
      }
      .views-field-title h2 {
        margin-bottom: 0;
        line-height: initial;
        margin-top: .4em;
        a {
          color: #231f20;
          text-decoration: none;
        }
      }
      .views-field-field-date-creation {
        top: -4px;
        color: #888888;
        font-size: 85%;
        display: inline-block;
        position: relative;
      }
      .views-field-body {
        margin-top: -10px;
        p {
          color: #333333;
          line-height: 18px;
        }
      }
    }
  }
  .view-footer .All-Actus-Link a {
    background-color: #96132b;
    color: #ffffff;
    padding: 5px 20px;
    border-radius: 15px;
    display: block;
    text-decoration: none;
    font-size: 80%;
    margin: 0 auto 20px;
    width: auto;
    max-width: 180px;
    text-align: center;
  }
  .views-field-field-societe {
    position: relative;
    left: 10px;
    display: inline-block;
    a {
      margin-right: 5px;
    }
  }
}
/*Actualites Homes Medical et Paramedical*/
.view-actualites-liste .view-content .item-list ul {
  margin: 0;
}
.home-medical .view-actualites-home-commun .item-list ul li .views-field-title h2 {
  margin-top: 0;
}
.home-paramedical {
  .view-actualites-home-commun .item-list ul li .views-field-title h2 {
    margin-top: 0;
  }
  > .view-actualites-home-commun {
    margin-top: 20px;
  }
}
.home-medical .view-actualites-home-commun .view-footer .All-Actus-Link a {
  background-color: #1badc7;
  font-size: 80%;
}
.view-actualites-home-commun {
  &.view-display-id-block_1,
  &.view-display-id-block_2 {
    top: -80px;
    position: relative;
  }
}
.home-paramedical .view-actualites-home-commun .view-footer .All-Actus-Link a {
  background-color: #f16804;
  font-size: 80%;
}
.view-actualites-liste {
  .views-field-field-accroche {
    margin-top: 20px;
  }
}

.epingle {
  background: $brand-primary;
  font-size: 19px;
  font-weight: bold;
  width: 118px;
  padding: 0 10px;
  color: $white;
  float: right;
  margin: 10px 0;
  &::before {
    font-family: 'FontAwesome';
    content: '\f005';  }
    &::after {
      content: 'A la une';
      text-align: right;
    }
}
