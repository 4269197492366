@media only screen and (min-width: 768px) {
  // Marges Contenu
  .contenu {
    padding-left: 6vw;
    padding-right: 6vw;
  }
  .navbar-header {
    .logo {
      padding: 0;
      img {
        max-width: 100px;
        box-shadow: 0 0 36px #777;
        padding: 10px 15px;
      }
    }
  }
  .tb-megamenu-button {
    top: -69px;
  }
  .bandeauphoto {
    max-width: 1524px;
    padding-bottom: 19.936%;
  }
  #block-block-3,
  #block-block-4 {
    ul {
      li {
        font-size: 14px;
      }
    }
  }
  .view-actualites-home-commun {
    .item-list ul {
      li {
        .views-field-field-image-actualite img {
          display: inline-block;
          float: left;
          margin-right: 15px;
          margin-bottom: 25px;
          border: 1px solid #ddd;
        }
      }
    }
  }
  // Fil d'ariane
  .breadcrumb {
    margin-left: 10%;
  }
  // Bloc de partage
  .block-addthis {
    margin-right: 2vw;
    top: 30px;
    .addthis_button {
      width: 36px;
      height: 38px;
    }
  }
  h1.page-header {
    span {
      // padding: 5px 60px 5px 0;
      // &::before {
      //   content: "";
      //   position: relative;
      //   width: 0;
      //   height: 0;
      //   border-style: solid;
      //   border-width: 20px 20px 0 20px;
      //   border-color: #595959 transparent transparent transparent;
      //   bottom: -74px;
      //   left: 40px;
      // }
    }
  }
  .view-annuaire-membres-sfd {
    ul.cardlist li.card {
      .enveloppe {
        height: 180px;
      }
    }
  }
  //Formulaire adhésion
  .mbs-inscription-form {
    .node-webform {
      .webform-client-form {
        .fieldset_wrapper_civi {
          .form-actions,
          .form-item {
            margin-top: 15px;
            margin-bottom: 0;
          }
          label {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: bold;
            width: 230px;
            text-align: right;
            .form-required {
              font-size: 20px;
              color: $red;
            }
          }
          input[type="checkbox"],
          input[type="radio"] {
            margin: 0 0 0 -20px;
          }
          .webform-component-select {
            .chosen-container {
              height: 25px;
              padding: 0;
            }
          }
          .webform-component-checkboxes,
          .webform-component-radios {
            margin-top: 0;
            .control-label {
              width: auto;
            }
          }
          // Champs interne / Etudiant
          .form-item-submitted-identite-fieldset-wrapper-civicrm-1-contact-1-cg1-custom-43 {
            .control-label {
              width: 230px;
            }
            .help-block {
              display: inline-block;
              margin-left: 0;
            }
            .form-radios {
              display: block;
              margin-left: 248px;
              label {
                display:inline-block;
                width: 50px;
              }
            }
          }
          .confirm-parent,
          .password-parent {
            width: 100%;
            label {
              display: inline-block;
            }
          }
          input.password-confirm,
          input.password-field {
            max-width: 100px;
          }
          .password-confirm,
          .password-strength {
            width: 260px;
            margin-top: 0;
          }
          .form-control {
            height: 30px;
            padding: 5px 12px;
            border-radius: 10px;
          }
          input.form-text {
            width: 250px;
          }
          .help-block {
            display: block;
            max-width: 390px;
            margin-left: 248px;
            margin-top: 4px;
          }
        }
        .password-suggestions {
          margin: auto;
          width: 50%;
          margin-top: 10px;
          ul {
            border: 1px solid #ddd;
            margin-top: 4%;
            font-size: .9em;
          }
        }
        // Etape 2
        // Champs téléphone
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box4-civicrm-1-contact-4-phone-phone-ext,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box5-civicrm-1-contact-5-phone-phone-ext,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box-civicrm-1-contact-1-phone-phone-ext,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box2-civicrm-1-contact-2-phone-phone-ext,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box3-civicrm-1-contact-3-phone-phone-ext {
          width: 115px;
          display: inline-block;
          label {
            width: 47px;
          }
          input.form-text {
            width: 50px;
          }
        }
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box4-civicrm-1-contact-4-phone-phone,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-adresse-personnelle-horizontale-box5-civicrm-1-contact-5-phone-phone,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box-civicrm-1-contact-1-phone-phone,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box2-civicrm-1-contact-2-phone-phone,
        .form-item-submitted-adresse-professionnelle-fieldset-wrapper-horizontale-box3-civicrm-1-contact-3-phone-phone {
          width: 64%;
          label {
            width: 100px;
          }
        }
        .webform-component--adresse-professionnelle--fieldset-wrapper--adresse-de-correspondance {
          margin-top: 40px !important;
          border: 1px dashed #000;
          padding: 15px;
        }
      }
      // Etape 3 :
      .form-item-submitted-annuaire-des-membres-fieldset-wrapper-horizontale-box6-civicrm-1-contact-6-phone-phone-ext {
        input.form-text {
          width: 60px;
        }
      }
      .form-item-submitted-annuaire-des-membres-fieldset-wrapper-horizontale-box6-civicrm-1-contact-6-phone-phone {
        label {
          width: 214px !important;
        }
      }
      // Etape 4 :
      .form-item-submitted-vie-professionnelle-fieldset-wrapper-civicrm-1-contact-1-cg2-custom-6 {
        display: table;
        label {
          display: table-cell;
          text-align: left;
        }
        .form-radios {
          display: table-cell !important;
          font-weight: normal !important;
          text-align: left !important;
          margin-right: 5px !important;
          width: 72%;
          label {
            text-align: left !important;
            font-weight: normal;
          }
        }
      }
      .form-item-submitted-vie-professionnelle-fieldset-wrapper-civicrm-1-contact-1-cg1-custom-19,
      .form-item-submitted-vie-professionnelle-fieldset-wrapper-civicrm-1-contact-1-cg2-custom-11,
      .form-item-submitted-vie-professionnelle-fieldset-wrapper-civicrm-1-contact-1-cg2-custom-12,
      .form-item-submitted-vie-professionnelle-fieldset-wrapper-civicrm-1-contact-1-cg2-custom-13,
      .form-item-submitted-vie-professionnelle-fieldset-wrapper-hbox-na-civicrm-1-contact-1-cg2-custom-8,
      .form-item-submitted-vie-professionnelle-fieldset-wrapper-hbox-na2-civicrm-1-contact-1-cg2-custom-9,
      .form-item-submitted-vie-professionnelle-fieldset-wrapper-hbox-na3-civicrm-1-contact-1-cg2-custom-10 {
        label {
          width: 150px !important;
          display: block;
          word-spacing: normal;
          font-weight: normal;
          text-align: left !important;
          margin-right: 5px !important;
        }
        .form-textarea-wrapper {
          width: 350px;
          height: 80px;
          textarea {
            width: 100%;
            height: 100% !important;
          }
        }
      }
    }
  }
  // Etape 5
  .webform-component--parrainage--parrainage {
    .webform-client-form {
      label {
        width: 180px;
      }
      .token-input-list li {
        background: #none;
      }
    }
  }
  button.webform-submit {
    background: #96132b;
    color: #fff;
    border-radius: 3px;
  }
  //Formulaire adhésion
  // .mbs-inscription-form {
  //   .webform-container-inline {
  //     label {
  //       width: 26%;
  //     }
  //   }
  //   .webform-component--Identite--fieldset-wrapper--hbox-na6--civicrm-1-contact-1-contact-suffix-id {
  //     width: 52%;
  //     label {
  //       width: 50%;
  //     }
  //     .webform-component--Identite--fieldset-wrapper--hbox-na6--na-field--titre {
  //       float: right;
  //     }
  //   }
  //   .form-item-submitted-identite-fieldset-wrapper-hbox-na6-na-field--titre-1 {
  //     margin: 0;
  //   }
  //   input {
  //     &.password-confirm,
  //     &.password-field {
  //       max-width: 25%;
  //     }
  //   }
  // }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer {
    #block-block-6 {
      a,
      p {
        &.ffrd-link {
          width: 70px;
          height: 38px;
        }
        &.ffd-link {
          width: 70px;
          height: 38px;
        }
        &.sfe-link {
          width: 70px;
          height: 40px;
        }
      }
    }
  }
  // Footer Vidéo
  .view-teasera-home-commun.view-display-id-block_3 .views-field-body {
    padding-bottom: 29%;
  }
  .view-teasera-home-commun .views-field-body {
    margin: auto;
  }
  // .footer-home {
  //   iframe {
  //     height: 600px;
  //   }
  // }
}
