/*Accès direct */
.slide {
  padding: 0;
  margin: 0;
}
.acces {
  padding: 0;
  margin: 0 0 25px;
}

#block-block-3,
#block-block-4 {
  z-index: 5;
  ul {
    margin: 0;
    padding: 0;
    li {
      margin-top: 1px;
      list-style: none;
      text-align: center;
      font-size: 14px;
      border-bottom: 1px solid $white;
      border-right: 1px solid $white;
      padding-top: 12px;
      background: $red;
      margin-right: -3px;
      display: inline-block;
      width: 19.8%;
      &:nth-child(4) {
        border-right: none;
      }
      a {
        text-align: center;
        color: $white;
        font-weight: normal;
        &:hover {
          text-decoration: none;
          img {
            opacity: 1;
          }
        }
        img {
          max-height: 36px;
          margin: 0 auto;
          opacity: .5;
          border: 0;
        }
      }
    }
    &:nth-child(1) a img {}
    &:nth-child(4) a img {
      max-width: 60px;
    }
  }
}
#block-block-4 {
  margin: 0 -15px;
  border-bottom: 1px solid $white;
  ul {
    background: $red;
    li {
      border-bottom: none;
    }
  }
}

@media only screen and (max-width: 1199px) {


#block-block-4 { margin-top: 30px; }
}
