/*********************************************/
/******** annuaire card list ************/
/*********************************************/

// Cahe le doublon "Accueil" dans le fil d'Ariane
.page-annuaire-membres-sfd {
  .breadcrumb {
    .delimiter, .last {
      display:none;
    }
  }
}

.enveloppe {
  z-index: 2;
  border: solid 1px #96132b;
  margin-bottom: 20px;
}
.card-med {
  .enveloppe {
    border: solid 1px #1badc7;
  }
  h2 span {
    font-weight: normal;
  }
}
.card-para {
  h2 span {
    font-weight: normal;
  }
  .enveloppe {
    border: solid 1px #f16804;
  }
}
.view-annuaire-membres-sfd {
  .view-filters {
    margin: 0 0 12px;
    width: 100%;
  }
  .view-content{
	display: block;
  }
  .view-content:first-child,
  .view-footer {
    display: inline-block;
  }
  form {
    background: none repeat scroll 0 0 #ebebeb;
    padding: 7px 0 14px 13px;
  }
}
/*.views-exposed-widget { width: 26%; }*/
.views-exposed-form .views-exposed-widget {
  padding: .5em 1em 0 0;
}
/*.view-annuaire-membres-sfd .views-exposed-form input { width: 96%; border: 1px solid #ddd; border-radius: 5px; height: 34px; line-height: 34px; padding: 0 0 0 10px; color: #222; font-size: 0.9em; }
.view-annuaire-membres-sfd .views-widget-filter-city, .view-annuaire-membres-sfd .views-widget-filter-country { clear:inherit; margin:0; }
.view-annuaire-membres-sfd .views-submit-button { clear:both; }
.view-annuaire-membres-sfd .form-select { background: none repeat scroll 0 0 #fff!important; border-radius: 5px!important; border: 1px solid #ddd!important; height: 34px; width: 100%; margin-top: 2px; }
.attachment .views-summary a { color: #fff; text-decoration: none; border-radius: 5px; background: #96132b; padding: 3px 6px; line-height: 2.5em; }*/
.view-annuaire-membres-sfd {
  div.reinitialiser {
    padding-top: 7px;
    padding-left: 25px;
    display: inline-block;
  }
  ul.cardlist li.card {
    .first-part {
      width: 100%;
      height: 95px;
    }
    .second-part {
      color: #000;
      margin-left: 10px;
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
}
.attachment {
  .views-summary {
    a {
      color: #111;
      text-decoration: none;
      border-radius: 5px;
      border: 1px solid #bbb;
      background: #ddd;
      padding: 6px 10px;
      margin-right: 5px;
      display: inline-block;
      margin-bottom: 5px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      display: none;
    }
  }
}
.view-annuaire-membres-sfd{
	.attachment {
	  .views-summary {
		&:nth-child(1),
		&:nth-child(2) {
			display:inline-block;
		}
	  }
	}
}
/*Cards*/
.view-annuaire-membres-sfd ul {
  &.cardlist {
    padding: 0;
    font-size: .8em;
    li.card {
      list-style: none;
      &::before {
        content: "";
      }
    }
  }
  &.pager {
    display: inline-flex;
    li {
      margin-right: 1px;
      margin-left: 1px;
      &.pager-last a,
      &.pager-next a,
      &.pager-previous a {
        width: 20px;
        color: transparent;
        background-color: transparent;
        width: 100%;
        height: 100%;
        display: block;
        font-size: .6em;
      }
      &.pager-first {
        a {
          width: 20px;
          color: transparent;
          background-color: transparent;
          width: 100%;
          height: 100%;
          display: block;
          font-size: .6em;
        }
        margin-top: 10px;
        padding: 3px;
      }
      &.pager-last,
      &.pager-next,
      &.pager-previous {
        margin-top: 10px;
        padding: 3px;
      }
      &.pager-first {
        background: url("../img/annuaire/premier.png") no-repeat center #efefef;
        background-size: 10%;
      }
      &.pager-previous {
        background: url("../img/annuaire/precedent.png") no-repeat center #efefef;
        margin-right: auto;
        position: relative;
        left: auto;
        background-size: 10%;
      }
      &.pager-next {
        background: url("../img/annuaire/suivant.png") no-repeat center #efefef;
        margin-left: auto;
        right: auto;
        float: right;
        position: relative;
        background-size: 10%;
      }
      &.pager-last {
        background: url("../img/annuaire/dernier.png") no-repeat center #efefef;
        right: auto;
        float: right;
        right: 0;
        position: relative;
        background-size: 10%;
      }
      &.pager-current {
        font-size: .6em;
        position: relative;
        margin-top: 10px;
        margin-right: 5px;
        padding: 4px 8px 4px 4px;
        text-align: center;
      }
      &.pager-ellipsis,
      &.pager-item {
        position: relative;
        margin-top: 10px;
        margin-right: 5px;
        padding: 4px 8px 4px 4px;
        text-align: center;
      }
      a {
        padding: 0;
        font-size: .6em;
        &:hover {
          padding: 0;
          font-size: .6em;
        }
      }
      &.pager-first:hover,
      &.pager-last:hover,
      &.pager-next:hover,
      &.pager-previous:hover {
        background-color: white;
      }
    }
  }
}
.view-annuaire-membres-sfd {
  ul.cardlist li {
    &.card h2 {
      background: none repeat scroll 0 0 #96132b;
      color: #fff;
      font-family: "Raleway", sans-serif;
      font-size: 15px;
      font-weight: bold;
      margin: 0;
      overflow: hidden;
      padding: 4px 4px 4px 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.card-med h2 {
      background: none repeat scroll 0 0 #1badc7;
    }
    &.card-para h2 {
      background: none repeat scroll 0 0 #f16804;
    }
  }
  .views-field-php {
    height: 25px;
  }
  .med-tag,
  .para-tag {
    position: relative;
    display: block;
    top: -20px;
    color: white;
    font-size: 11px;
    text-align: right;
    right: 10px;
  }
  .profession {
    clear: right;
    color: #686868;
    font-weight: bold;
    font-size: 15px;
    font-family: "Columbia-DemiBold", Tahoma, Geneva, sans-serif;
    margin-left: 90px;
    margin-top: 9px;
    margin-bottom: 0;
    line-height: 27px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  /*.specialite { font-family: 'Columbia-DemiBold', Tahoma, Geneva, sans-serif; }
  .professionBloc { margin-left: 30%; margin-top: 3%; }*/
  /*Photos*/
  ul.cardlist li.card {
    .img_card {
      margin-left: 12px;
      margin-top: 0;
      float: left;
      img {
        width: 67px;
        height: 86px;
      }
    }
    .no_picture {
      background: url("../img/annuaire/image-generique.png") no-repeat;
      height: 86px;
      width: 67px;
    }
    .infoBase {
      h3 {
        clear: right;
        color: #000000;
        margin: 9px 0 0 87px;
      }
      .adresse {
        color: #000;
        margin-left: 87px;
        margin-top: 10px;
        margin-bottom: 15px;
      }
      .mail {
        margin-top: 5px;
        word-break: break-all;
        margin-left: 87px;
        .pictoMail {
          background: url("../img/annuaire/pictoMail.png") no-repeat scroll 0 2px rgba(0, 0, 0, 0);
          float: left;
          height: 17px;
          margin-right: 6px;
          margin-top: 2px;
          width: 19px;
        }
      }
      .tel {
        margin-left: 87px;
        .pictoTel {
          background: url("../img/annuaire/pictoTel.png") no-repeat scroll 0 2px rgba(0, 0, 0, 0);
          float: left;
          height: 20px;
          margin-right: 6px;
          width: 18px;
        }
      }
    }
    .infoSupp {
      cursor: pointer;
      margin-top: 0;
      height: 206px;
      overflow: hidden;
      background: none repeat scroll 0 0 #111;
      opacity: .9;
      .contenu-supp {
        padding-top: 10px;
        .background-supp {
          background: none repeat scroll 0 0 #111;
          opacity: .9;
        }
      }
      .languette {
        background: url("../images/annuaire/tirette-v2.png") no-repeat scroll center 2px rgba(0, 0, 0, 0);
        cursor: pointer;
        height: 16px;
      }
      .contenu-supp {
        h3 {
          color: #888;
          font-size: 12px;
          margin: 0 0 2px 14px;
        }
        ul {
          color: #fff;
          margin-left: 15px;
          padding-bottom: 2px;
          width: 90%;
          li.renseignements {
            color: #fff;
            display: inline;
            margin: 0;
            font-size: 11px;
          }
        }
        p {
          color: #ffffff;
          font-size: 1em;
          line-height: 13px;
          margin: 0 0 16px 16px;
        }
      }
    }
  }
  /*2nd Div masquee*/




  // Correction Views ajax pager alignment is broken
  // https://www.drupal.org/node/2200753
  ul.pagination > li > a {
    &.progress-disabled {
      float: left;
      .glyphicon {margin-left: 3px;}
    }
  }



}
