.webform-client-form-324 {
  .webform-component--adresse-sfd {
    border: 1px solid #ccc;
    margin-right: 5vw;
    margin-top: 1vh;
    margin-bottom: 6vh;
    padding: 13px 12px 5px 22px;
    width: 100%;
    border-radius: 10px;
    h2,
    h3 {
      margin-bottom: 0;
    }
  }
  .panel {
    border: none;
  }
  .panel-default {
    > .panel-heading {
      border: none;
      border-radius: 20px;
      margin: 10px auto 0 auto;
    }
  }
  .form-inline .form-control {
    display: block;
    width: 100%;
  }
  .webform-container-inline {
    &.webform-component-textarea {
      .form-textarea-wrapper {
        display: block;
      }
    }
  }
  .webform-component-select .form-control {
    height: 34px;
    border: 1px solid #ccc;
  }
  .formulaire_sfd {
    // legend {
    //   display: none;
    // }
  }
}
