/*Agenda Home Titre*/
#block-views-agenda-home-commun-block h2.block-title,
#block-views-agenda-home-commun-block-1 h2.block-title,
#block-views-agenda-home-commun-block-2 h2.block-title {
  margin-bottom: 20px;
  margin-top: 0;
  margin-left: 30px;
  color: #231f20;
  font-weight: 600;
  font-size: 140%;
}
/*Agenda Home*/
#block-views-agenda-home-commun-block-1 {
  margin-top: 34px;
}
#block-views-agenda-home-commun-block-2 {
  margin-top: 40px;
}
#block-views-agenda-home-commun-block,
#block-views-agenda-home-commun-block-1,
#block-views-agenda-home-commun-block-2 {
  padding-top: 10px;
  background: url("../img/agenda-home-bg-bottom.svg") no-repeat center bottom #e9e9e9;
  padding-bottom: 90px;
  margin-bottom: 20px;
  ul {
    padding-left: 15px;
    padding-right: 15px;
    li {
      &.item-evenement {
        clear: both;
        display: table;
        margin-bottom: 1.5em;
        padding-bottom: 1.5em;
        border-bottom: 1px solid #ddd;
        box-shadow: 0px 1px 0px #fff;
      }
      .views-field-nothing {
        display: table-cell;
        // float: left;
        // margin-right: 4%;
      }
      div.date-evenement {
        background: url("../img/agenda-home-bg-date.png") no-repeat left top;
        width: 48px;
        float: left;
        .date-jour {
          font-weight: bold;
          font-size: 150%;
          color: #000000;
          height: 46px;
          line-height: 52px;
          text-align: center;
        }
        .date-mois {
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          font-size: 80%;
        }
      }
      .views-field-nothing-1 {
        display: table-cell;
        vertical-align: top;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }
      .views-field-title h3 {
        margin-top: 0;
        margin-bottom: 0;
        a {
          color: #231f20;
          text-decoration: none;
          display: inline-block;
          font-size: 90%;
          margin-top: 0;
          line-height: 22px;
        }
      }
      .views-field-field-description-courte {
        line-height: 18px;
        font-size: 84%;
      }
      .views-field-field-societe ul {
        width: 100%;
        margin-top: 5px;
        padding: 0;
        li {
          margin: 0;
          list-style: none;
          .Medical, .Paramedical {display:inline-block;}
          a {
            font-size: 75%;
            padding: 2px 15px;
            background-color: #000000;
            color: #ffffff;
            text-decoration: none;
            font-weight: bold;
            border-radius: 20px;
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 7px;
          }
          .Medical a {
            background-color: #1badc7;
          }
          .Paramedical a {
            background-color: #f16804;
          }
        }
      }
    }
    // &:after {
    //   content: "";
    //   height:1px; border: 1px solid #000;
    //   width: 100%;
    //
    // }
  }
  .view-footer .All-Actus-Link a {
    background-color: #96132b;
    color: #ffffff;
    padding: 5px 20px;
    border-radius: 15px;
    display: block;
    text-decoration: none;
    font-size: 80%;
    margin: auto;
    width: auto;
    max-width: 110px;
    text-align: center;
  }
}
/*agenda vue Calendar et Vue liste*/
#calendar_div,
.calendar,
.calendar_control,
.calendar_header,
.calendar_links {
  width: 100%;
}
.calendar-calendar tr {
  border-bottom: none;
}
.region-content .view-agenda {
  ul.pager {
    border: none;
  }
  .date-nav-wrapper li {
    &.date-prev {
      background: none;
      padding: 0;
      border: none;
      left: 0;
      text-align: left;
      top: -20px;
    }
    &.date-next {
      background: none;
      padding: 0;
      border: none;
      top: -20px;
    }
  }
}
.view .date-nav-wrapper {
  .date-prev a {
    margin-left: 0;
    color: #ffffff;
    background: #96132b;
  }
  .date-next a {
    margin-right: 0;
    color: #ffffff;
    background: #96132b;
  }
}
.region-content .view-agenda .calendar-calendar td .inner div.calendar div {
  &.views-field-title {
    color: #000000;
    a {
      color: #000000;
    }
  }
  &.views-field-field-lieu-evenement {
    font-size: 100%;
    color: #ffffff;
    a {
      font-size: 100%;
      color: #ffffff;
    }
  }
  &.contents {
    a {
      font-size: 140%;
      line-height: 1.3em;
      color: #000000;
    }
    .location span {
      font-size: 100%;
      color: #ffffff;
    }
  }
}
.calendar-calendar {
  .day-view .full td {
    &.multi-day div,
    &.single-day div {
      &.Medical {
        background-color: #1BADC7;
      }
      &.Paramedical {
        background-color: #F16804;
      }
    }
  }
  .month-view .full td {
    &.multi-day div,
    &.single-day div {
      &.Medical {
        background-color: #1BADC7;
      }
      &.Paramedical {
        background-color: #F16804;
      }
    }
  }
  .week-view .full td {
    &.multi-day div,
    &.single-day div {
      &.Medical {
        background-color: #1BADC7;
      }
      &.Paramedical {
        background-color: #F16804;
      }
    }
  }
  .day-view .full td {
    &.multi-day div {
      &._medical_cache_,
      &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
    &.single-day div {
      &._medical_cache_,
      &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .month-view .full td {
    &.multi-day div {
      &._medical_cache_,
      &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
    &.single-day div {
      &._medical_cache_,
      &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .week-view .full td {
    &.multi-day div {
      &._medical_cache_,
      &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
    &.single-day div {
      &._medical_cache_,
      &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .month-view .full td.multi-day .inner .monthview .cutoff,
  .week-view .full td.multi-day .inner .weekview .cutoff {
    background: none;
  }
  .day-view .full td.multi-day div.dayview,
  .month-view .full td.multi-day div.monthview,
  .week-view .full td.multi-day div.weekview {
    height: 7em;
  }
}
.switchCalendarLink {
  position: absolute;
  right: 10%;
  z-index: 10;
  .swithToMonthLink {
    text-indent: -5000px;
    background: url("../images/calendar-vue-mois.png") no-repeat left top;
    height: 27px;
    width: 27px;
    display: inline-block;
    margin-right: 10px;
  }
  .swithToListLink {
    text-indent: -5000px;
    background: url("../images/calendar-vue-liste.png") no-repeat left center;
    height: 27px;
    width: 27px;
    display: inline-block;
  }
}
.region-content .view-agenda.view-display-id-page_1 .view-content ul {
  padding: 0;
  li {
    list-style: none;
    background: none;
    clear: both;
    border-bottom: 1px solid #ccc;
    padding: 5px 0 10px 15px;
    display: inline-block;
    list-style: none;
    background: none;
    clear: both;
    border-bottom: 1px solid #ccc;
    padding: 5px 0 10px;
    display: inline-block;
    width: 100%;
    img {
      max-width: 100%;
      height: auto !important;
    }
    h2 a {
      text-decoration: none;
    }
    .views-field-field-type-evenement {
      float: right;
      background-color: #e9e9e9;
      color: #333333;
      padding: 5px;
      border-radius: 8px;
      a {
        color: #333333;
        text-decoration: none;
      }
    }
    .views-field-field-dates {
      font-weight: bold;
    }
  }
}
/* pager */
.item-list ul {
  &.pager {
    border: medium none;
    width: 100% !important;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    font-size: 1em;
    li {
      border: medium none;
      padding: 0;
      background: #efefef;
      border: 1px solid #ccc;
      &:hover {
        background: #fff;
      }
      a {
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
        padding: 15px 20px;
      }
      &.pager-current {
        padding: 15px 20px;
        background: #96132b;
        margin-right: 2em;
      }
      &.pager-first,
      &.pager-last {
        display: none;
      }
      &.pager-item {
        margin-right: 2em;
        a {}
      }
      &.pager-next {
        right: 0;
        a {}
      }
      &.pager-previous {
        margin-right: 4em;
        a {}
      }
      &.pager-ellipsis {
        background: none;
        border: none;
        padding: 0;
        margin-top: 12px;
        margin-right: 16px;
      }
      &.last {}
    }
  }
  &.pager-show-more li a {
    background: #96132b;
    padding: 15px 20px;
    color: #ffffff;
    border-radius: 5px;
  }
  &.pager-show-more-empty li {
    padding: 15px 20px;
  }
}



// Agenda
/*agenda vue Calendar et Vue liste*/

#calendar_div, .calendar_control, .calendar_links, .calendar_header, .calendar {
  width: 100%;
}

.calendar-calendar tr {
  border-bottom: none;
}

.region-content .view-agenda {
  ul.pager {
    border: none;
  }
  .date-nav-wrapper li {
    &.date-prev {
      background: none;
      padding: 0;
      border: none;
      left: 0;
      text-align: left;
      top: -20px;
    }
    &.date-next {
      background: none;
      padding: 0;
      border: none;
      top: -20px;
    }
  }
}

.view .date-nav-wrapper {
  .date-prev a {
    margin-left: 0;
    color: #ffffff;
    background: #96132b;
  }
  .date-next a {
    margin-right: 0;
    color: #ffffff;
    background: #96132b;
  }
}

.region-content .view-agenda .calendar-calendar td .inner div.calendar div {
  &.views-field-title {
    color: #000000;
    a {
      color: #000000;
    }
  }
  &.views-field-field-lieu-evenement {
    font-size: 100%;
    color: #ffffff;
    a {
      font-size: 100%;
      color: #ffffff;
    }
  }
  &.contents {
    a {
      font-size: 140%;
      line-height: 1.3em;
      color: #000000;
    }
    .location span {
      font-size: 100%;
      color: #ffffff;
    }
  }
}

.calendar-calendar {
  .day-view .full td {
    &.multi-day div, &.single-day div {
      &.Medical {
        background-color: #1BADC7;
      }
      &.Paramedical {
        background-color: #F16804;
      }
    }
  }
  .month-view .full td {
    &.multi-day div, &.single-day div {
      &.Medical {
        background-color: #1BADC7;
      }
      &.Paramedical {
        background-color: #F16804;
      }
    }
  }
  .week-view .full td {
    &.multi-day div, &.single-day div {
      &.Medical {
        background-color: #1BADC7;
      }
      &.Paramedical {
        background-color: #F16804;
      }
    }
  }
  .day-view .full td {
    &.multi-day div {
      &._medical_cache_, &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
    &.single-day div {
      &._medical_cache_, &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .month-view .full td {
    &.multi-day div {
      &._medical_cache_, &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
    &.single-day div {
      &._medical_cache_, &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .week-view .full td {
    &.multi-day div {
      &._medical_cache_, &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
    &.single-day div {
      &._medical_cache_, &._paramedical_cache_ {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .month-view .full td.multi-day .inner .monthview .cutoff, .week-view .full td.multi-day .inner .weekview .cutoff {
    background: none;
  }
  .month-view .full td.multi-day div.monthview, .week-view .full td.multi-day div.weekview, .day-view .full td.multi-day div.dayview {
    height: 7em;
  }
}

.switchCalendarLink {
  position: absolute;
  right: 10%;
  z-index: 10;
  .swithToMonthLink {
    text-indent: -5000px;
    background: url(../images/calendar-vue-mois.png) no-repeat left top;
    height: 27px;
    width: 27px;
    display: inline-block;
    margin-right: 10px;
  }
  .swithToListLink {
    text-indent: -5000px;
    background: url(../images/calendar-vue-liste.png) no-repeat left center;
    height: 27px;
    width: 27px;
    display: inline-block;
  }
}
